import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import cross from '../../../../../../img/realties/cross.png'
import { CSSTransition } from 'react-transition-group'
import CustomTextField from '../../../../../../component/Form/CustomTextField'
import { CooperationButtonText, CooperationConfirmation, CooperationForm, CooperationFormButton, CooperationFormInputs, CooperationModalBody, CooperationModalCross, CooperationModalText, CooperationModalTitle, CooperationModalWrapper } from './index.styled'
import { cities } from '../../../../../RealtyPage/components/RealtyForm/constant'
import CustomAutocomplete from '../../../../../../component/Form/CustomAutocomplete'
import { getHost } from '../../../../../../api/auth/host'
import { useSelector } from 'react-redux'
import { selectUserData } from '../../../../../../store/auth/selector'
import { createHostCooperationRequest } from '../../../../../../api/auth/hostCooperation'

const CooperationModal = ({ modalIsOpen, setModalIsOpen, setRequestedModalIsOpen }) => {
	const modalRef = useRef(null)
	const { handleSubmit, control, reset } = useForm()
	const [loading, setLoading] = useState(false)
	const { userId } = useSelector(selectUserData)

	useEffect(() => {
		getHost(userId).then(res => {
			reset({ ...res.data, phone: res.data.phone.replace('380', '') })
		})
	}, [])

	const handleBodyClick = e => {
		e.stopPropagation()
	}

	const handleClose = () => {
		setModalIsOpen(false)
	}

	const onSubmit = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		const resultPhone = '380' + data.phone
		try {
			await createHostCooperationRequest({ city: data.city, name: data.name, phone: resultPhone })
		} catch (error) {
			toast.error(error.message)
		}
		setLoading(false)
		await setModalIsOpen(false)
		setRequestedModalIsOpen(true)
	}

	const handlePhoneFilter = value => {
		if (!value) {
			return value
		}
		let filteredValue = value.replaceAll(/[^0-9]/g, '')
		if (filteredValue.length > 9) {
			filteredValue = filteredValue.slice(0, 9)
		}
		return filteredValue
	}

	const handlePhoneFormat = value => {
		let formattedValue = ''
		if (value.length > 0) {
			formattedValue += '(' + value.slice(0, 2)
		}
		if (value.length >= 3) {
			formattedValue += ') ' + value.slice(2, 5)
		}
		if (value.length >= 6) {
			formattedValue += '-' + value.slice(5, 7)
		}
		if (value.length >= 8) {
			formattedValue += '-' + value.slice(7, 9)
		}
		return formattedValue
	}

	return (
		<CSSTransition nodeRef={modalRef} in={modalIsOpen} timeout={10} classNames="active" unmountOnExit>
			<CooperationModalWrapper ref={modalRef} onClick={handleClose}>
				<CooperationModalBody onClick={handleBodyClick}>
					<CooperationModalCross onClick={handleClose}>
						<img src={cross} alt="Cross" />
					</CooperationModalCross>
					<CooperationModalTitle>Ваші дані</CooperationModalTitle>
					<CooperationModalText>Заповніть усі поля нижче, щоб відправити заявку на послугу</CooperationModalText>
					<CooperationForm onSubmit={handleSubmit(onSubmit)}>
						<CooperationFormInputs>
							<CustomAutocomplete
								control={control}
								name="city"
								label="Місто"
								required={true}
								options={cities}
								onValue={v => cities.find(city => city.value === v)?.label || null}
								onChange={(e, value) => {
									return value?.value
								}}
							/>
							<CustomTextField control={control} required={true} label="Ім’я" name="name" />
							<CustomTextField control={control} label="Номер телефону" name="phone" prefix="+380" filter={handlePhoneFilter} format={handlePhoneFormat} required="Номер телефону обовʼязковий" minLength={{ value: 9, message: 'Номер телефону введений не повністю' }} disabled={true} />
						</CooperationFormInputs>
						<CooperationFormButton style={{ marginTop: '44px' }}>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <CooperationButtonText>Відправити заявку</CooperationButtonText>}</CooperationFormButton>
						<CooperationConfirmation>Натискаючи кнопку «Відправити заявку», ви погоджуєтеся на обробку персональних даних.</CooperationConfirmation>
					</CooperationForm>
				</CooperationModalBody>
			</CooperationModalWrapper>
		</CSSTransition>
	)
}

export default CooperationModal
