export const BookingStatus = {
	CONFIRMATION_PENDING: 'CONFIRMATION_PENDING',
	VIEWING_PENDING: 'VIEWING_PENDING',
	VIEWING_COMPLETED: 'VIEWING_COMPLETED',
	CHECK_IN_SUCCEEDED: 'CHECK_IN_SUCCEEDED',

	REJECTED: 'REJECTED',
	REALTY_NOT_AVAILABLE: 'REALTY_NOT_AVAILABLE'
}

export const getBookingStatusColor = bookingStatus => {
	if (bookingStatus === BookingStatus.CONFIRMATION_PENDING) {
		return 'warning'
	} else if (bookingStatus === BookingStatus.VIEWING_PENDING || bookingStatus === BookingStatus.VIEWING_COMPLETED) {
		return 'success'
	} else if (bookingStatus === BookingStatus.REJECTED || bookingStatus === BookingStatus.REALTY_NOT_AVAILABLE) {
		return 'error'
	} else if (bookingStatus === BookingStatus.CHECK_IN_SUCCEEDED) {
		return 'success'
	} else {
		return ''
	}
}

export const getBookingStatusText = bookingStatus => {
	if (bookingStatus === BookingStatus.CONFIRMATION_PENDING) {
		return 'Заявка на перегляд'
	} else if (bookingStatus === BookingStatus.VIEWING_PENDING) {
		return 'Перегляд житла'
	} else if (bookingStatus === BookingStatus.VIEWING_COMPLETED) {
		return 'Перегляд відбувся'
	} else if (bookingStatus === BookingStatus.REJECTED) {
		return 'Скасована'
	} else if (bookingStatus === BookingStatus.REALTY_NOT_AVAILABLE) {
		return 'Неактуальний об’єкт'
	} else if (bookingStatus === BookingStatus.CHECK_IN_SUCCEEDED) {
		return 'Успішне заселення'
	} else {
		return 'Невідомий статус'
	}
}
