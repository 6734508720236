import styled from 'styled-components'

export const TextInputWrapper = styled.label`
	position: relative;
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	border-radius: 12px;
	border: 1px solid #cecece;
	padding: 5px 15px;
	cursor: pointer;

	&.focused {
		border: 2px solid #724fff;
		padding: 4px 14px;
	}

	&.error {
		border-color: #e43027;
	}

	&.disabled {
		cursor: not-allowed;
	}
`

export const TextInputLabel = styled.div`
	font-size: 12px;
	color: #b4b4b4;
	background: #ffffff;
	padding: 2px;
	position: absolute;
	top: -11px;
	left: auto;
	transition: all 0.2s ease-in-out;

	&:not(.labelUp) {
		top: 12px;
		font-size: 15px;
		color: #b4b4b4;
	}

	&.focused {
		color: #724fff;
	}

	&.error {
		color: #e43027;
	}
`

export const TextInputPrefix = styled.div`
	font-size: 15px;
	color: #282828;
	font-style: normal;
	font-weight: 400;
	flex-shrink: 0;
	margin-right: 5px;
`

export const TextInputInput = styled.input`
	display: block;
	border: none;
	width: 100%;
	height: 100%;
	font-size: 15px;
	color: #282828;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`

export const TextInputError = styled.div`
	height: 14px;
	position: absolute;
	bottom: -19px;
	left: 0;
	font-size: 12px;
	color: #e43027;
`

export const ShowPassword = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 16px;
	right: 15px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
	}
`

export const PasswordValidation = styled.div`
	position: absolute;
	bottom: -37px;
	left: 0;
	width: 100%;
`

export const PasswordValidationLines = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`

export const PasswordValidationLine = styled.div`
	width: 100%;
	height: 3px;
	border-radius: 10px;
	background: ${props => props.fill || 'rgba(238, 238, 238)'};
`

export const PasswordValidationText = styled.div`
	margin-top: 5px;
	text-align: right;
	font-size: 12px;
	line-height: 19px;
	color: #b4b4b4;
`
