import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'

export const CooperationModalWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(40, 40, 40, 0.5);
	transition: all 0.1s ease-in-out;
	z-index: 1;
	opacity: 0;

	&.active-enter-done {
		opacity: 1;
	}
`

export const CooperationModalBody = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	background: #ffffff;
	padding: 25px;
	width: 410px;
	border-radius: 12px;
	transition: all 0.1s ease-in-out;

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
	}
`

export const CooperationModalCross = styled.div`
	display: flex;
	width: 12px;
	height: 12px;
	position: absolute;
	top: 31px;
	right: 25px;
	cursor: pointer;
`

export const CooperationModalTitle = styled.div`
	font-size: 20px;
	font-weight: 500;
	color: #282828;
	line-height: 23px;
`

export const CooperationModalText = styled.div`
	font-size: 15px;
	font-weight: 400;
	color: #282828;
	line-height: 25px;
	margin-top: 15px;
`

export const CooperationForm = styled.form`
	margin-top: 32px;
`

export const CooperationFormInputs = styled.div`
	padding-top: 0.01%;
	display: flex;
	flex-direction: column;
	gap: 43px;
`

export const CooperationFormButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 48px;
	border-radius: 12px;
	border: none;
	background: #724fff;
	cursor: pointer;
	color: #ffffff;

	&:disabled {
		background: #cecece;
		cursor: not-allowed;
	}
`

export const CooperationButtonText = styled.div`
	color: #ffffff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const CooperationConfirmation = styled.div`
	margin-top: 25px;
	font-size: 12px;
	color: #b4b4b4;
	font-weight: 400;
	line-height: 19px;
	width: 311px;
`
