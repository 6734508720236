import React from 'react'
import {
	ActionButtons,
	EditButton,
	RealtiesBody,
	RealtiesGreetingsButton,
	RealtiesGreetingsInfo,
	RealtiesGreetingsText,
	RealtiesGreetingsTitle,
	RealtiesTableBody,
	RealtiesTableButtonsRow,
	RealtiesTableHead,
	RealtiesTableHeadTr,
	RealtiesTableRow,
	RealtiesTableTd,
	RealtiesTableTh,
	RealtyBlock,
	RealtyImage,
	RealtyTable
} from './index.styled'
import { formatBigPricePerMonth, formatPublicId, getCityText, getDistrictText } from '../../helper'
import NewRealtyButton from '../NewRealtyButton'
import { useNavigate } from 'react-router-dom'

const RealtiesTable = ({ realties, buttons }) => {
	const navigate = useNavigate()

	const handleNewRealtyClick = () => {
		navigate('/realties/new')
	}

	return (
		<RealtiesBody>
			{realties.length ? (
				realties.map(realty => (
					<RealtyBlock key={realty._id}>
						<RealtyTable>
							<RealtiesTableHead>
								<RealtiesTableHeadTr>
									<RealtiesTableTh>№</RealtiesTableTh>
									<RealtiesTableTh>Фото</RealtiesTableTh>
									<RealtiesTableTh>Ціна</RealtiesTableTh>
									<RealtiesTableTh>Місто</RealtiesTableTh>
									<RealtiesTableTh>Район</RealtiesTableTh>
									<RealtiesTableTh>Адреса</RealtiesTableTh>
								</RealtiesTableHeadTr>
							</RealtiesTableHead>
							<RealtiesTableBody>
								<RealtiesTableRow>
									<RealtiesTableTd>{formatPublicId(realty.publicId)}</RealtiesTableTd>
									<RealtiesTableTd>
										<RealtyImage src={realty.images?.[0]} />
									</RealtiesTableTd>
									<RealtiesTableTd>{formatBigPricePerMonth(realty.minPrice, realty.currency)}</RealtiesTableTd>
									<RealtiesTableTd>{getCityText(realty.city)}</RealtiesTableTd>
									<RealtiesTableTd>{getDistrictText(realty.district)}</RealtiesTableTd>
									<RealtiesTableTd>
										{realty.address.street} {realty.address.streetNumber}
									</RealtiesTableTd>
								</RealtiesTableRow>
								<RealtiesTableButtonsRow>
									<RealtiesTableTd colSpan={6}>
										<ActionButtons>
											{buttons.map(button => (
												<EditButton key={button.title} onClick={() => button.onClick(realty)} className={button.type}>
													{button.icon && <img src={button.icon} alt="Icon" />}
													{button.title}
												</EditButton>
											))}
										</ActionButtons>
									</RealtiesTableTd>
								</RealtiesTableButtonsRow>
							</RealtiesTableBody>
						</RealtyTable>
					</RealtyBlock>
				))
			) : (
				<RealtyBlock>
					<RealtiesGreetingsInfo>
						<RealtiesGreetingsTitle>Вітаємо у Josti!</RealtiesGreetingsTitle>
						<RealtiesGreetingsText>Додавайте оголошення відслідковуйте вашу угоди та отримуйте пасивний дохід</RealtiesGreetingsText>
						<RealtiesGreetingsButton>
							<NewRealtyButton onClick={handleNewRealtyClick} />
						</RealtiesGreetingsButton>
					</RealtiesGreetingsInfo>
				</RealtyBlock>
			)}
		</RealtiesBody>
	)
}

export default RealtiesTable
