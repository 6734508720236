import React from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { RadioGroupFormControl, RadioGroupLabel } from './index.styled'

const RadioGroupStyled = styled(RadioGroup)(() => ({
	'&.MuiFormGroup-root': {
		gap: '77px',
		marginTop: '15px',
		'@media screen and (max-width: 720px)': {
			flexDirection: 'column',
			gap: '15px'
		}
	}
}))

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
	'&.MuiFormControlLabel-root': {
		padding: '0',
		margin: '0'
	},
	'& .MuiTypography-root': {
		fontSize: '15px'
	},
	'&.error .MuiTypography-root': {
		color: '#e43027'
	}
}))

const RadioStyled = styled(Radio)(() => ({
	'&.MuiRadio-root': {
		padding: '0',
		marginRight: '10px'
	},
	'& .MuiSvgIcon-root': {
		width: '25px',
		height: '25px',
		fill: '#CECECE'
	},
	'&.MuiRadio-root.Mui-checked .MuiSvgIcon-root': {
		fill: '#724FFF'
	},
	'&.error .MuiSvgIcon-root': {
		fill: '#e43027'
	}
}))

const CustomRadioGroup = ({ control, label, name, options, required }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required } })
	const layout = error ? ' error' : ''

	return (
		<RadioGroupFormControl>
			<RadioGroupLabel className={layout}>{label}</RadioGroupLabel>
			<RadioGroupStyled row name={name} value={field.value || null} onChange={field.onChange}>
				{options.map(option => (
					<FormControlLabelStyled key={option.value} value={option.value} control={<RadioStyled inputRef={field.ref} className={layout} />} label={option.label} className={layout} />
				))}
			</RadioGroupStyled>
		</RadioGroupFormControl>
	)
}

export default CustomRadioGroup
