import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { MainProgressWrapper } from './index.styled'

const PrivatePage = React.memo(({ component: Component }) => {
	const hasAccessToken = useSelector(state => !!state.auth.accessToken)

	useEffect(() => {
		if (!hasAccessToken) {
			window.open('/', '_self')
		}
	}, [hasAccessToken])

	return (
		<>
			{hasAccessToken ? (
				<Component />
			) : (
				<MainProgressWrapper>
					<CircularProgress color="primary" size={60} thickness={4} />
				</MainProgressWrapper>
			)}
		</>
	)
})

export default PrivatePage
