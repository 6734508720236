import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { bookingServiceUrl } from '../../../urls'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest'
}

const accessToken = localStorage.accessToken

if (accessToken) {
	headers.Authorization = `Bearer ${accessToken}`
}

const bookingInstance = axios.create({
	baseURL: bookingServiceUrl,
	headers
})

bookingInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error))

bookingInstance.interceptors.response.use(
	response => response,
	error => {
		return responseInterceptor(error)
	}
)

export default bookingInstance
