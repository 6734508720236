import React from 'react'
import { HeaderBurgerLine, HeaderBurgerWrapper } from './index.styled'

const HeaderBurger = ({ isOpen = false, onClick }) => {
	const layout = isOpen ? 'active' : ''
	return (
		<HeaderBurgerWrapper className={layout} onClick={onClick}>
			<HeaderBurgerLine className={layout} />
			<HeaderBurgerLine className={layout} />
			<HeaderBurgerLine className={layout} />
		</HeaderBurgerWrapper>
	)
}

export default HeaderBurger
