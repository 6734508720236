import { store } from '../store'
import { logoutThunk } from '../store/auth/thunk'

const responseInterceptor = error => {
	const originalRequest = error.config

	if (error?.response?.status !== 401) {
		return Promise.reject(error)
	}

	if (error?.response?.status === 401 && (originalRequest.url === '/auth/login' || originalRequest.url === '/auth/less')) {
		return Promise.reject(error)
	}

	if (error?.response?.status === 401) {
		return new Promise(() => {
			store.dispatch(logoutThunk())
		})
	}

	return Promise.reject(error)
}

export default responseInterceptor
