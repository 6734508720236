import React from 'react'
import { TabHeaderBack, TabHeaderBackArrow, TabHeaderBackTitle, TabHeaderTab, TabHeaderTabs, TabHeaderTitle, TabHeaderWrapper } from './index.styled'

const TabHeader = ({ back, title, tabs }) => {
	const handleTabClick = tab => {
		tab.onClick()
	}

	return (
		<TabHeaderWrapper>
			{back && (
				<TabHeaderBack onClick={back.onClick}>
					<TabHeaderBackArrow>
						<svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7L16 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM16 7L1 7L1 9L16 9L16 7Z" />
						</svg>
					</TabHeaderBackArrow>
					<TabHeaderBackTitle>{back.title}</TabHeaderBackTitle>
				</TabHeaderBack>
			)}
			<TabHeaderTitle>{title}</TabHeaderTitle>
			{tabs?.length && (
				<TabHeaderTabs>
					{tabs.map(tab => (
						<TabHeaderTab key={tab.title} className={tab.active ? 'active' : ''} onClick={() => handleTabClick(tab)}>
							{tab.title}
						</TabHeaderTab>
					))}
				</TabHeaderTabs>
			)}
		</TabHeaderWrapper>
	)
}

export default TabHeader
