import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		// eslint-disable-next-line
		console.error('Catch error: ', window.location.pathname, error, info.componentStack)
	}

	handleReload() {
		window.location.reload()
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="main">
					<h1>Ooops, something went wrong :(</h1>
					<button onClick={this.handleReload}>Reload</button>
				</div>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
