import React, { useEffect, useState } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { updateRealty } from '../../api/realty/realty'
import { useDispatch, useSelector } from 'react-redux'
import TabHeader from '../../component/TabHeader'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import { useNavigate } from 'react-router-dom'
import AskModal from '../../component/AskModal'
import NewRealtyButton from './component/NewRealtyButton'
import { Box } from '@mui/material'
import { getRealtiesThunk } from '../../store/realty/thunk'
import RealtiesTable from './component/RealtiesTable'
import pencil from '../../img/realties/pencil.png'
import CooperationBlock from './component/CooperationBlock'

const MyRealtiesPage = () => {
	const accessToken = useSelector(state => state.auth.accessToken)
	const realties = useSelector(state => state.realty.realties)
	const navigate = useNavigate()
	const [realtyToArchive, setRealtyToArchive] = useState()
	const dispatch = useDispatch()

	useEffect(() => {
		if (accessToken) {
			dispatch(getRealtiesThunk(null, false, 0, 100))
		}
	}, [accessToken])

	const handleMyRealtiesClick = () => {
		navigate('/realties')
	}

	const handleMyBookingsClick = () => {
		navigate('/bookings')
	}

	const handleMyArchivesClick = () => {
		navigate('/archives')
	}

	const handleNewRealtyClick = () => {
		navigate('/realties/new')
	}

	const handleEditRealty = realty => {
		navigate(`/realties/${realty._id}/edit`)
	}

	const handleOpenArchiveModal = realty => {
		setRealtyToArchive(realty)
	}

	const handleCloseArchiveModal = () => {
		setRealtyToArchive(null)
	}

	const handleConfirmArchive = async () => {
		await updateRealty(realtyToArchive._id, { ...realtyToArchive, archived: true })
		dispatch(getRealtiesThunk(null, false, 0, 100))
		handleCloseArchiveModal()
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<TabHeader
					back={null}
					title="Мої оголошення"
					tabs={[
						{ title: 'Ваше житло', onClick: handleMyRealtiesClick, active: true },
						{ title: 'Ваші угоди', onClick: handleMyBookingsClick },
						{ title: 'Архів', onClick: handleMyArchivesClick }
					]}
				/>
				<RealtiesTable
					realties={realties}
					buttons={[
						{ title: 'Перенести в архів', onClick: handleOpenArchiveModal },
						{ title: 'Редагувати', onClick: handleEditRealty, icon: pencil, type: 'filled' }
					]}
				/>
				{realties?.length && realties.length < 3 ? (
					<Box sx={{ marginTop: '40px', marginLeft: 'auto', width: 'fit-content' }}>
						<NewRealtyButton onClick={handleNewRealtyClick} />
					</Box>
				) : null}
				<CooperationBlock />
				<AskModal isOpen={!!realtyToArchive} title="Додати це оголошення у архів?" text="Після підтвердження це оголошення потрапить у архіви" handleClose={handleCloseArchiveModal} handleConfirm={handleConfirmArchive} />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default MyRealtiesPage
