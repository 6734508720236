import React, { useEffect, useState } from 'react'
import Header from '../../../component/Header'
import Footer from '../../../component/Footer'
import { PageContainer, PageWrapper } from '../../../style/page/index.styled'
import { useNavigate, useParams } from 'react-router-dom'
import { getRealty, updateRealty } from '../../../api/realty/realty'
import RealtyForm from '../components/RealtyForm'

const EditRealtyPage = () => {
	const { realtyId } = useParams()
	const navigate = useNavigate()
	const [realty, setRealty] = useState()

	useEffect(() => {
		getRealty(realtyId).then(res => {
			setRealty(res.data.realty)
		})
	}, [])

	const handleSave = async data => {
		await updateRealty(realtyId, data)
		navigate('/realties')
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<RealtyForm onSave={handleSave} initialRealty={realty} />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default EditRealtyPage
