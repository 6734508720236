import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	realties: []
}

const realtySlice = createSlice({
	name: 'realty',
	initialState,
	reducers: {
		setRealties: (state, action) => {
			state.realties = action.payload
		}
	}
})

export const { setRealties } = realtySlice.actions
export const realtyReducer = realtySlice.reducer
