import { realtyServiceUrl } from '../../../urls'
import realtyInstance from '../instance'
import axios from 'axios'

export const getPresignedImage = () => {
	return realtyInstance.get(`${realtyServiceUrl}/host/images`)
}

export const createImage = (file, signData) => {
	const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${signData.cloudname}/auto/upload`
	const formData = new FormData()
	formData.append('file', file)
	formData.append('public_id', signData.fileName)
	formData.append('api_key', signData.apikey)
	formData.append('signature', signData.signature)
	formData.append('timestamp', signData.paramsToSign.timestamp)
	formData.append('eager', signData.paramsToSign.eager)
	formData.append('folder', signData.paramsToSign.folder)
	formData.append('format', signData.paramsToSign.format)
	return axios.post(cloudinaryUrl, formData)
}
