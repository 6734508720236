import React, { useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import cross from '../../../../img/realties/cross.png'
import { CSSTransition } from 'react-transition-group'
import { PayoutButtonText, PayoutConfirmation, PayoutForm, PayoutFormButton, PayoutFormInputs, PayoutModalBody, PayoutModalCross, PayoutModalText, PayoutModalTitle, PayoutModalWrapper } from './index.styled'
import CustomTextField from '../../../../component/Form/CustomTextField'
import { createHostPayoutRequest } from '../../../../api/booking/balance'
import { formatBigPriceWithCents } from '../../../MyRealtiesPage/helper'

const PayoutModal = ({ balance, modalIsOpen, setModalIsOpen, setRequestedModalIsOpen }) => {
	const modalRef = useRef(null)
	const { handleSubmit, control } = useForm()
	const [loading, setLoading] = useState(false)

	const handleBodyClick = e => {
		e.stopPropagation()
	}

	const handleClose = () => {
		setModalIsOpen(false)
	}

	const onSubmit = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		try {
			await createHostPayoutRequest({ cardNumber: data.cardNumber })
		} catch (error) {
			toast.error(error.message)
		}
		setLoading(false)
		await setModalIsOpen(false)
		setRequestedModalIsOpen(true)
	}

	const handleCardFilter = value => {
		if (!value) {
			return value
		}
		let filteredValue = value.replaceAll(/[^0-9]/g, '')
		if (filteredValue.length > 16) {
			filteredValue = filteredValue.slice(0, 16)
		}
		return filteredValue
	}

	const handleCardFormat = value => {
		const cleanedValue = value.replace(/\D/g, '')
		return cleanedValue.match(/.{1,4}/g)?.join(' ') || ''
	}

	return (
		<CSSTransition nodeRef={modalRef} in={modalIsOpen} timeout={10} classNames="active" unmountOnExit>
			<PayoutModalWrapper ref={modalRef} onClick={handleClose}>
				<PayoutModalBody onClick={handleBodyClick}>
					<PayoutModalCross onClick={handleClose}>
						<img src={cross} alt="Cross" />
					</PayoutModalCross>
					<PayoutModalTitle>Заявка на вивід коштів</PayoutModalTitle>
					<PayoutModalText>Доступно до виводу {formatBigPriceWithCents(balance.total, balance.currency)}</PayoutModalText>
					<PayoutForm onSubmit={handleSubmit(onSubmit)}>
						<PayoutFormInputs>
							<CustomTextField control={control} label="Номер банківської картки" name="cardNumber" filter={handleCardFilter} format={handleCardFormat} required="Номер банківської картки обовʼязковий" minLength={{ value: 16, message: 'Номер банківської картки введений не повністю' }} />
						</PayoutFormInputs>
						<PayoutFormButton style={{ marginTop: '44px' }}>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <PayoutButtonText>Відправити заявку</PayoutButtonText>}</PayoutFormButton>
						<PayoutConfirmation>Натискаючи кнопку «Відправити заявку», ви погоджуєтеся на обробку персональних даних.</PayoutConfirmation>
					</PayoutForm>
				</PayoutModalBody>
			</PayoutModalWrapper>
		</CSSTransition>
	)
}

export default PayoutModal
