import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const RealtiesBody = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	gap: 15px;
`

export const RealtyBlock = styled.div`
	background: #ffffff;
	padding: 13px 25px 25px;
	border-radius: 12px;

	@media screen and (max-width: ${theme.point720}) {
		overflow-x: scroll;
	}
`

export const RealtyTable = styled.table`
	width: 100%;

	@media screen and (max-width: ${theme.point720}) {
		min-width: 680px;
	}
`

export const RealtiesTableHead = styled.thead`
	height: 45px;
`

export const RealtiesTableHeadTr = styled.tr``

export const RealtiesTableTh = styled.th`
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		width: 15%;
	}

	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6) {
		width: 18%;
	}
`

export const RealtiesTableBody = styled.tbody``

export const RealtiesTableRow = styled.tr`
	height: 100px;
	border-top: 1px solid rgba(206, 206, 206, 0.5);
`

export const RealtiesTableButtonsRow = styled.tr`
	height: auto;
	margin-top: 25px;
`

export const RealtiesTableTd = styled.td`
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
`

export const RealtyImage = styled.img`
	width: 70px;
	height: 70px;
	object-fit: cover;
	border-radius: 7px;
`

export const ActionButtons = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding-top: 0.01%;
`

export const EditButton = styled.div`
	width: auto;
	min-width: 200px;
	height: 38px;
	padding: 5px;
	display: flex;
	align-items: center;
	gap: 6px;
	justify-content: center;
	cursor: pointer;
	background: transparent;
	border: 1px solid #724fff;
	border-radius: 10px;
	font-size: 15px;
	font-weight: 500;
	color: #724fff;

	&.filled {
		background: #724fff;
		color: #ffffff;
	}

	> img {
		width: 14px;
		height: 14px;
	}

	&:hover {
		opacity: 0.5;
	}
`

export const RealtiesGreetingsInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 27px;
	margin-bottom: 15px;
`

export const RealtiesGreetingsTitle = styled.div`
	color: #282828;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const RealtiesGreetingsText = styled.div`
	color: #b4b4b4;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	margin-top: 15px;
	max-width: 227px;
`

export const RealtiesGreetingsButton = styled.div`
	margin-top: 25px;
`
