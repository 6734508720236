import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authReducer } from './auth/reducer'
import { realtyReducer } from './realty/reducer'
import { bookingReducer } from './booking/reducer'

export const rootReducer = combineReducers({
	auth: authReducer,
	realty: realtyReducer,
	booking: bookingReducer
})

export const store = configureStore({ reducer: rootReducer })
