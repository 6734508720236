import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import styled from 'styled-components'
import { useController } from 'react-hook-form'

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
	width: '100%',
	'&.MuiFormControlLabel-root': {
		margin: 0
	},
	'& .MuiFormControlLabel-label': {
		fontSize: '15px',
		color: '#282828'
	}
}))

const SwitchStyled = styled(Switch)(() => ({
	'&.MuiSwitch-root': {
		width: 'auto',
		height: 'auto',
		padding: '0',
		marginRight: '15px'
	},
	'& .MuiSwitch-track': {
		width: '46px',
		height: '28px',
		borderRadius: '14px'
	},
	'& .MuiButtonBase-root': {
		width: '18px',
		height: '18px',
		padding: '0',
		marginTop: '5px',
		marginLeft: '5px'
	},
	'& .MuiSwitch-thumb': {
		width: '18px',
		height: '18px',
		boxShadow: 'none'
	},
	'&.MuiSwitch-root .MuiSwitch-input': {
		width: '100%',
		left: '0'
	},
	'& .Mui-checked': {
		'.MuiSwitch-thumb': {
			boxShadow: 'none',
			backgroundColor: '#ffffff'
		}
	},
	'& .Mui-checked+.MuiSwitch-track': {
		opacity: '1 !important'
	},
	'& .MuiButtonBase-root.Mui-checked': {
		transform: 'translateX(18px)'
	}
}))

const CustomSwitch = ({ control, label, name }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required: false }, defaultValue: false })

	return <FormControlLabelStyled {...{ label }} control={<SwitchStyled {...{ name, error, ...field, value: !!field.value, checked: !!field.value, inputRef: field.ref }} />} />
}

export default CustomSwitch
