import React, { useEffect, useState } from 'react'
import { RegistrationButtonText, RegistrationForm, RegistrationFormButton, RegistrationFormInputs, RegistrationFormSubmission, RegistrationFormSubtitle, RegistrationFormTitle } from '../../index.styled'
import { createHostContext } from '../../../../api/auth/hostContext'
import { CircularProgress } from '@mui/material'
import CustomTextField from '../../../../component/Form/CustomTextField'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { landingUiUrl } from '../../../../urls'

const CreateProfile = ({ hostData, setHostData, setHostContext }) => {
	const { handleSubmit, control, reset, setError } = useForm()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (hostData) {
			reset({ ...hostData })
		}
	}, [hostData])

	const onSubmitCreateProfile = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		const resultPhone = '380' + data.phone
		try {
			const res = await createHostContext({ ...data, phone: resultPhone })
			setHostContext({ ...res.data })
			setHostData({ ...data, resultPhone })
		} catch (error) {
			if (error.response?.data?.code === 'host_already_exists') {
				setError('phone', { type: 'custom', message: 'Номер телефону вже існує' })
			} else {
				toast.error(error.message)
			}
		}
		setLoading(false)
	}

	const handlePhoneFilter = value => {
		if (!value) {
			return value
		}
		let filteredValue = value.replaceAll(/[^0-9]/g, '')
		if (filteredValue.length > 9) {
			filteredValue = filteredValue.slice(0, 9)
		}
		return filteredValue
	}

	const handlePhoneFormat = value => {
		let formattedValue = ''
		if (value.length > 0) {
			formattedValue += '(' + value.slice(0, 2)
		}
		if (value.length >= 3) {
			formattedValue += ') ' + value.slice(2, 5)
		}
		if (value.length >= 6) {
			formattedValue += '-' + value.slice(5, 7)
		}
		if (value.length >= 8) {
			formattedValue += '-' + value.slice(7, 9)
		}
		return formattedValue
	}

	return (
		<RegistrationForm onSubmit={handleSubmit(onSubmitCreateProfile)}>
			<RegistrationFormTitle>Створити профіль</RegistrationFormTitle>
			<RegistrationFormSubtitle>Заповніть усі поля нижче, щоб створити свій профіль</RegistrationFormSubtitle>
			<RegistrationFormInputs>
				<CustomTextField control={control} label="Ім’я" name="name" required="Ім’я обовʼязкове" minLength={{ value: 3, message: 'Ім’я занадто коротке' }} />
				<CustomTextField control={control} label="Прізвище" name="surname" required="Прізвище обовʼязкове" minLength={{ value: 3, message: 'Прізвище занадто коротке' }} />
				<CustomTextField control={control} label="Номер телефону" name="phone" prefix="+380" filter={handlePhoneFilter} format={handlePhoneFormat} required="Номер телефону обовʼязковий" minLength={{ value: 9, message: 'Номер телефону введений не повністю' }} />
				<CustomTextField control={control} label="Пароль" name="password" type="password" required="Пароль обовʼязковий" minLength={{ value: 8, message: 'Пароль повинен мати мінімум 8 символів' }} withPasswordLevel={true} />
			</RegistrationFormInputs>
			<RegistrationFormButton style={{ marginTop: '62px' }}>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <RegistrationButtonText>Зареєструватись</RegistrationButtonText>}</RegistrationFormButton>
			<RegistrationFormSubmission>
				Реєструючись, ви погоджуєтеся з <span onClick={() => window.open(`${landingUiUrl}/terms-of-use`, '_blank')}>угодою користувача</span> і <span onClick={() => window.open(`${landingUiUrl}/privacy-policy`, '_blank')}>політикою конфіденційності</span> платформи josti
			</RegistrationFormSubmission>
		</RegistrationForm>
	)
}

export default CreateProfile
