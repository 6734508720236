import React, { useRef } from 'react'
import cross from '../../../../../../img/realties/cross.png'
import { CSSTransition } from 'react-transition-group'
import { CooperationButtonText, CooperationFormButton, CooperationRequestedModalBody, CooperationRequestedModalCross, CooperationRequestedModalText, CooperationRequestedModalTitle, CooperationRequestedModalWrapper } from './index.styled'

const CooperationRequestedModal = ({ requestedModalIsOpen, setRequestedModalIsOpen }) => {
	const modalRef = useRef(null)

	const handleBodyClick = e => {
		e.stopPropagation()
	}

	const handleClose = () => {
		setRequestedModalIsOpen(false)
	}

	return (
		<CSSTransition nodeRef={modalRef} in={requestedModalIsOpen} timeout={10} classNames="active" unmountOnExit>
			<CooperationRequestedModalWrapper ref={modalRef} onClick={handleClose}>
				<CooperationRequestedModalBody onClick={handleBodyClick}>
					<CooperationRequestedModalCross onClick={handleClose}>
						<img src={cross} alt="Cross" />
					</CooperationRequestedModalCross>
					<CooperationRequestedModalTitle>Ваша заявка відправлена!</CooperationRequestedModalTitle>
					<CooperationRequestedModalText>Найближчим часом з вами зв’яжеться наш менеджер для уточнення деталей</CooperationRequestedModalText>
					<CooperationFormButton style={{ marginTop: '40px' }} onClick={() => setRequestedModalIsOpen(false)}>
						<CooperationButtonText>Зрозуміло</CooperationButtonText>
					</CooperationFormButton>
				</CooperationRequestedModalBody>
			</CooperationRequestedModalWrapper>
		</CSSTransition>
	)
}

export default CooperationRequestedModal
