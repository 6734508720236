export const theme = {
	// Breakpoints
	point1660: '1660px', // 1661-.. desktop
	point1440: '1440px', // 1441-1660 desktop
	point1280: '1280px', // 1281-1440 desktop
	point1024: '1024px', // 1025-1280 desktop
	point820: '820px', // 821-1024 desktop
	point720: '720px', // 721-820 tablet
	point520: '520px', // 521-720 mobile (sometimes tablet)
	point400: '400px' // ..-400 mobile
}
