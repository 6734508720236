import { useEffect, useState } from 'react'
import { getBreakPoint } from '../../helper'

const isWindowBreakPoint = point => {
	const { innerWidth: width } = window
	return width <= point
}

export const useWindowBreakPoint = breakpoint => {
	const point = getBreakPoint(breakpoint)
	const [isBreakPoint, setIsBreakPoint] = useState(isWindowBreakPoint(point))

	useEffect(() => {
		function handleResize() {
			const isPoint = isWindowBreakPoint(point)
			if (isPoint !== isBreakPoint) {
				setIsBreakPoint(isPoint)
			}
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [isBreakPoint])

	return isBreakPoint
}
