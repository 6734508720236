import bookingInstance from '../instance'

export const getAllBookings = ({ statuses }, page = 0, size = 20) => {
	return bookingInstance.get(`/host/bookings?page=${page}` + `&size=${size}` + (statuses ? `&statuses=${statuses}` : ''))
}

export const cancelBooking = bookingId => {
	return bookingInstance.post(`/host/bookings/${bookingId}/cancel`)
}

export const acceptBooking = bookingId => {
	return bookingInstance.post(`/host/bookings/${bookingId}/accept`)
}
