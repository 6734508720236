import styled from 'styled-components'
import { theme } from '../../style/theme'

export const BookingsBody = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	gap: 15px;
`

export const BookingWrap = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
`

export const BookingStatusWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

export const BookingStatusIcon = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
	border-radius: 5px;

	> svg {
		width: 18px;
		height: 18px;
	}

	&.warning {
		> svg {
			fill: #ff9700;
		}
	}

	&.success {
		> svg {
			fill: #54c940;
		}
	}

	&.error {
		> svg {
			fill: #ff385c;
		}
	}
`

export const BookingStatusText = styled.div`
	font-size: 17px;
	font-weight: 500;

	&.warning {
		color: #ff9700;
	}

	&.success {
		color: #54c940;
	}

	&.error {
		color: #ff385c;
	}
`

export const BookingsTableWrap = styled.div`
	background: #ffffff;
	padding: 25px;
	border-radius: 12px;
	width: 100%;

	@media screen and (max-width: ${theme.point720}) {
		overflow-x: scroll;
	}
`

export const BookingsTable = styled.table`
	width: 100%;
	margin-top: 40px;

	@media screen and (max-width: ${theme.point720}) {
		min-width: 600px;
	}
`

export const BookingsTableHead = styled.thead`
	height: 30px;
`

export const BookingsTableTh = styled.th`
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	vertical-align: top;
	padding: 0 2px;
	height: 30px;

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		width: 16%;
	}

	&:nth-child(4) {
		width: 38%;
	}

	&:nth-child(5) {
		width: 14%;
	}
`

export const BookingsTableBody = styled.tbody``

export const BookingsTableRow = styled.tr`
	height: auto;
	border-top: 1px solid rgba(206, 206, 206, 0.5);
`

export const BookingsTableTd = styled.td`
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	padding: 15px 2px;
`

export const BookingRealtyImage = styled.img`
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 7px;
`

export const BookingDetailList = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

export const BookingDetailValue = styled.div`
	width: auto;
	height: 35px;
	background: #f5f5f5;
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 1px 15px;
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;
	white-space: nowrap;
`

export const BookingButtons = styled.div`
	margin-top: 25px;
	display: flex;
	align-items: center;
	gap: 10px;
`

export const EditButton = styled.div`
	width: auto;
	min-width: 200px;
	height: 38px;
	padding: 5px;
	display: flex;
	align-items: center;
	gap: 6px;
	justify-content: center;
	cursor: pointer;
	background: transparent;
	border: 1px solid #724fff;
	border-radius: 10px;
	font-size: 15px;
	font-weight: 500;
	color: #724fff;

	&.filled {
		background: #724fff;
		color: #ffffff;
	}

	> img {
		width: 14px;
		height: 14px;
	}

	&:hover {
		opacity: 0.5;
	}
`
