import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import MyRealtiesPage from '../pages/MyRealtiesPage'
import BookingsPage from '../pages/BookingsPage'
import ArchivesPage from '../pages/ArchivesPage'
import NewRealtyPage from '../pages/RealtyPage/NewRealtyPage'
import EditRealtyPage from '../pages/RealtyPage/EditRealtyPage'
import ProfilePage from '../pages/ProfilePage'
import PrivatePage from '../component/PrivatePage'
import LoginPage from '../pages/LoginPage'
import RegistrationPage from '../pages/RegistrationPage'
import RecoveryPage from '../pages/RecoveryPage'
import BalancePage from '../pages/BalancePage'

const Main = memo(() => {
	return (
		<Routes>
			<Route path="/" element={<LoginPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/registration" element={<RegistrationPage />} />
			<Route path="/password-recovery" element={<RecoveryPage />} />
			<Route path="/profile" element={<PrivatePage component={ProfilePage} />} />
			<Route path="/balance" element={<PrivatePage component={BalancePage} />} />
			<Route path="/realties/*" element={<PrivatePage component={MyRealtiesPage} />} />
			<Route path="/realties/new" element={<PrivatePage component={NewRealtyPage} />} />
			<Route path="/realties/:realtyId/edit" element={<PrivatePage component={EditRealtyPage} />} />
			<Route path="/bookings/*" element={<PrivatePage component={BookingsPage} />} />
			<Route path="/archives/*" element={<PrivatePage component={ArchivesPage} />} />

			{/*<Route element={<PrivatePage component={() => <h1>404</h1>} />} />*/}
		</Routes>
	)
})

export default Main
