import styled from 'styled-components'

export const NewRealtyButtonWrapper = styled.div`
	width: auto;
	max-width: fit-content;
	padding: 5px 25px 5px 23px;
	background: #724fff;
	height: 48px;
	border-radius: 12px;
	font-size: 15px;
	font-weight: 400;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	cursor: pointer;
	user-select: none;

	&:hover {
		opacity: 0.5;
	}
`
