import React, { useEffect, useState } from 'react'
import {
	BookingButtons,
	BookingDetailList,
	BookingDetailValue,
	BookingRealtyImage,
	BookingsBody,
	BookingsTable,
	BookingsTableBody,
	BookingsTableHead,
	BookingsTableRow,
	BookingsTableTd,
	BookingsTableTh,
	BookingsTableWrap,
	BookingStatusIcon,
	BookingStatusText,
	BookingStatusWrap,
	BookingWrap,
	EditButton
} from './index.styled'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { useDispatch, useSelector } from 'react-redux'
import TabHeader from '../../component/TabHeader'
import { formatBigPricePerMonth, formatPublicId, getFormattedDateDay, getFormattedPhone } from '../MyRealtiesPage/helper'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import { useNavigate } from 'react-router-dom'
import AskModal from '../../component/AskModal'
import { getBookingsThunk } from '../../store/booking/thunk'
import { acceptBooking, cancelBooking } from '../../api/booking/booking'
import { BookingStatus, getBookingStatusColor, getBookingStatusText } from './helper'
import { CircularProgress } from '@mui/material'

const BookingsPage = () => {
	const accessToken = useSelector(state => state.auth.accessToken)
	const bookings = useSelector(state => state.booking.bookings)
	const [bookingToCancel, setBookingToCancel] = useState(null)
	const [loading, setLoading] = useState(false)
	const [selectedStatus, setSelectedStatus] = useState(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const loadBookings = async () => {
		dispatch(getBookingsThunk({ statuses: selectedStatus }, 0, 100))
	}

	useEffect(() => {
		if (accessToken) {
			loadBookings()
		}
	}, [!accessToken, selectedStatus])

	const handleBack = () => {
		navigate('/realties')
	}

	const handleStatusClick = status => {
		setSelectedStatus(status)
	}

	const handleCancelBooking = booking => {
		setBookingToCancel(booking._id)
	}

	const handleCloseCancelModal = () => {
		setBookingToCancel(null)
	}

	const handleConfirmCancel = async () => {
		await cancelBooking(bookingToCancel)
		await loadBookings()
		handleCloseCancelModal()
	}

	const handleAcceptBooking = async booking => {
		setLoading(true)
		await acceptBooking(booking._id)
		await loadBookings()
		setLoading(false)
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<TabHeader
					back={{ title: 'До ваших оголошень', onClick: handleBack }}
					title="Ваші угоди"
					tabs={[
						{ title: 'Усі', onClick: () => handleStatusClick(null), active: selectedStatus === null },
						{ title: 'Заявка на перегляд', onClick: () => handleStatusClick(BookingStatus.CONFIRMATION_PENDING), active: selectedStatus === BookingStatus.CONFIRMATION_PENDING },
						{ title: 'Перегляд житла', onClick: () => handleStatusClick(BookingStatus.VIEWING_PENDING + ',' + BookingStatus.VIEWING_COMPLETED), active: selectedStatus === BookingStatus.VIEWING_PENDING + ',' + BookingStatus.VIEWING_COMPLETED },
						{ title: 'Скасовані', onClick: () => handleStatusClick(BookingStatus.REJECTED), active: selectedStatus === BookingStatus.REJECTED }
					]}
				/>
				<BookingsBody>
					{bookings?.map(booking => (
						<BookingWrap key={booking._id}>
							<BookingsTableWrap>
								<BookingStatusWrap>
									<BookingStatusIcon className={getBookingStatusColor(booking.status)}>
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M8.40975 0.101294C7.98976 0.270212 0.452707 5.93462 0.25974 6.22741C-0.171597 6.88056 -0.0580874 7.81524 0.543515 8.3783C0.929448 8.74992 1.81483 8.88506 2.25751 8.65983C2.40508 8.58101 3.94881 7.44362 5.69686 6.14858C7.44491 4.84228 8.93189 3.78372 9 3.78372C9.06811 3.78372 10.5551 4.84228 12.3031 6.14858C14.0512 7.44362 15.5949 8.58101 15.7425 8.65983C16.1852 8.88506 17.0706 8.74992 17.4565 8.3783C18.0581 7.81524 18.1716 6.88056 17.7403 6.22741C17.5359 5.91209 9.98754 0.258951 9.5562 0.101294C9.20432 -0.0338411 8.75028 -0.0338411 8.40975 0.101294Z" />
											<path d="M7.50231 6.22745C5.51589 6.71169 3.85864 8.33331 3.32515 10.3603C3.13218 11.0698 3.10948 12.8828 3.27974 13.6148C3.60892 15.0337 4.83483 16.6441 6.08344 17.3085C7.78609 18.2094 9.96547 18.2319 11.7703 17.3648C12.1222 17.1959 12.7124 16.7455 13.1324 16.3288C14.3129 15.1689 14.8464 13.8288 14.8464 12.0383C14.8464 10.304 14.2788 8.88511 13.1324 7.74772C11.7362 6.36259 9.48873 5.75448 7.50231 6.22745ZM9.56819 9.79727C11.0665 10.2027 11.5773 12.5 10.4536 13.7387C9.54549 14.7409 7.84284 14.5382 7.21854 13.3446C6.82617 12.5943 6.83159 11.4623 7.16178 10.8333C7.44556 10.2928 7.91095 9.90988 8.43309 9.79727C9.0574 9.66213 9.0574 9.66213 9.56819 9.79727Z" />
										</svg>
									</BookingStatusIcon>
									<BookingStatusText className={getBookingStatusColor(booking.status)}>{getBookingStatusText(booking.status)}</BookingStatusText>
								</BookingStatusWrap>
								<BookingsTable>
									<BookingsTableHead>
										<tr>
											<BookingsTableTh>№</BookingsTableTh>
											<BookingsTableTh>Фото</BookingsTableTh>
											<BookingsTableTh>Ціна</BookingsTableTh>
											<BookingsTableTh>Орендар</BookingsTableTh>
											<BookingsTableTh>Дата</BookingsTableTh>
										</tr>
									</BookingsTableHead>
									<BookingsTableBody>
										<BookingsTableRow>
											<BookingsTableTd>{formatPublicId(booking.flat.publicId)}</BookingsTableTd>
											<BookingsTableTd>
												<BookingRealtyImage src={booking.flat.images?.[0]} />
											</BookingsTableTd>
											<BookingsTableTd>{formatBigPricePerMonth(booking.flat.minPrice, booking.flat.currency)}</BookingsTableTd>
											<BookingsTableTd>
												<BookingDetailList>
													<BookingDetailValue>
														{booking.user?.surname} {booking.user?.name}
													</BookingDetailValue>
													<BookingDetailValue>{getFormattedPhone(booking.user?.phone)}</BookingDetailValue>
												</BookingDetailList>
											</BookingsTableTd>
											<BookingsTableTd>{getFormattedDateDay(new Date(booking.createdAt))}</BookingsTableTd>
										</BookingsTableRow>
									</BookingsTableBody>
								</BookingsTable>
								{booking.status === BookingStatus.CONFIRMATION_PENDING && (
									<BookingButtons>
										<EditButton onClick={() => handleCancelBooking(booking)}>Скасувати</EditButton>
										<EditButton className={'filled' + (loading ? ' disabled' : '')} onClick={() => handleAcceptBooking(booking)}>
											{loading ? (
												<>
													Підтвердження.. <CircularProgress color="inherit" sx={{ padding: '10px' }} />
												</>
											) : (
												'Підтвердити заявку'
											)}
										</EditButton>
									</BookingButtons>
								)}
							</BookingsTableWrap>
						</BookingWrap>
					))}
				</BookingsBody>
				<AskModal isOpen={!!bookingToCancel} title="Скасувати це бронювання?" text="Після підтвердження це бронювання буде скасовано" handleClose={handleCloseCancelModal} handleConfirm={handleConfirmCancel} />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default BookingsPage
