import React from 'react'
import { TextField } from '@mui/material'
import styled from 'styled-components'
import { useController } from 'react-hook-form'

export const TextFieldStyled = styled(TextField)(() => ({
	width: '100%',
	'.MuiInputBase-root': {
		minHeight: '48px',
		borderRadius: '12px',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#CECECE'
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#CECECE'
		},
		'&.Mui-focused': {
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#724FFF'
			}
		},
		'&.Mui-error': {
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#d32f2f'
			}
		}
	},
	'.MuiFormLabel-root': {
		fontSize: '15px',
		lineHeight: 'normal',
		color: '#B4B4B4'
	},
	'.MuiInputBase-input': {
		height: '1em',
		fontSize: '15px',
		lineHeight: 'normal',
		color: '#282828'
	},
	'.MuiOutlinedInput-notchedOutline legend': {
		fontSize: '1em',
		lineHeight: 'normal'
	}
}))

const CustomTextArea = ({ control, label, type, name }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required: true } })

	return <TextFieldStyled {...{ label, type, name, variant: 'outlined', value: field.value || '', inputRef: field.ref, onChange: field.onChange, error, multiline: true, minRows: 6 }} />
}

export default CustomTextArea
