import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomTextField from '../../component/Form/CustomTextField'
import { CircularProgress } from '@mui/material'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import Header from '../../component/Header'
import { FormBlock, FormButton, FormButtons, FormInputs, FormTitle, FormWrapper } from './index.styled'
import Footer from '../../component/Footer'
import { useForm } from 'react-hook-form'
import TabHeader from '../../component/TabHeader'
import { getHost, updateHost } from '../../api/auth/host'
import { useSelector } from 'react-redux'
import { selectUserData } from '../../store/auth/selector'

const ProfilePage = () => {
	const navigate = useNavigate()
	const { handleSubmit, control, reset } = useForm()
	const [loading, setLoading] = useState(false)
	const { userId } = useSelector(selectUserData)

	useEffect(() => {
		getHost(userId).then(res => {
			reset({ ...res.data })
		})
	}, [])

	const handleBack = () => {
		navigate('/realties')
	}

	const handleCancel = () => {}

	const onSubmit = async data => {
		setLoading(true)
		await updateHost(userId, { ...data })
		setLoading(false)
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<FormWrapper onSubmit={handleSubmit(onSubmit)}>
					<TabHeader back={{ title: 'До ваших оголошень', onClick: handleBack }} title="Налаштування профілю" tabs={null} />
					<FormBlock>
						<FormTitle>Персональні дані</FormTitle>
						<FormInputs>
							<CustomTextField control={control} required={true} label="Ім’я" name="name" />
							<CustomTextField control={control} required={true} label="Прізвище" name="surname" />
						</FormInputs>
						<FormTitle>Контактні дані</FormTitle>
						<FormInputs>
							<CustomTextField control={control} required={true} label="Номер телефону" name="phone" type="number" disabled={true} />
							<CustomTextField control={control} required={false} label="Електронна адреса" name="email" />
						</FormInputs>
						<FormButtons>
							<FormButton type="button" className="transparent disabled" onClick={handleCancel}>
								Видалити профіль
							</FormButton>
							<FormButton className={loading ? 'disabled' : ''}>
								{loading ? (
									<>
										Збереження... <CircularProgress color="inherit" sx={{ padding: '10px' }} />
									</>
								) : (
									'Зберегти профіль'
								)}
							</FormButton>
						</FormButtons>
					</FormBlock>
				</FormWrapper>
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default ProfilePage
