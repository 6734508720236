import React, { useRef } from 'react'
import cross from '../../../../img/realties/cross.png'
import { CSSTransition } from 'react-transition-group'
import { PayoutButtonText, PayoutFormButton, PayoutRequestedModalBody, PayoutRequestedModalCross, PayoutRequestedModalText, PayoutRequestedModalTitle, PayoutRequestedModalWrapper } from './index.styled'

const PayoutRequestedModal = ({ requestedModalIsOpen, setRequestedModalIsOpen }) => {
	const modalRef = useRef(null)

	const handleBodyClick = e => {
		e.stopPropagation()
	}

	const handleClose = () => {
		setRequestedModalIsOpen(false)
	}

	return (
		<CSSTransition nodeRef={modalRef} in={requestedModalIsOpen} timeout={10} classNames="active" unmountOnExit>
			<PayoutRequestedModalWrapper ref={modalRef} onClick={handleClose}>
				<PayoutRequestedModalBody onClick={handleBodyClick}>
					<PayoutRequestedModalCross onClick={handleClose}>
						<img src={cross} alt="Cross" />
					</PayoutRequestedModalCross>
					<PayoutRequestedModalTitle>Ваша заявка відправлена!</PayoutRequestedModalTitle>
					<PayoutRequestedModalText>Найближчим часом з вами зв’яжеться наш менеджер для уточнення деталей</PayoutRequestedModalText>
					<PayoutFormButton style={{ marginTop: '40px' }} onClick={() => setRequestedModalIsOpen(false)}>
						<PayoutButtonText>Зрозуміло</PayoutButtonText>
					</PayoutFormButton>
				</PayoutRequestedModalBody>
			</PayoutRequestedModalWrapper>
		</CSSTransition>
	)
}

export default PayoutRequestedModal
