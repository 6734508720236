import styled from 'styled-components'

export const RadioGroupFormControl = styled.div`
	margin-top: 25px;
`

export const RadioGroupLabel = styled.label`
	font-style: normal;
	font-size: 15px;
	color: #282828;
	font-weight: 400;

	&.error {
		color: #e43027;
	}
`
