import React from 'react'
import {
	FooterContact,
	FooterContactDescription,
	FooterContactPhone,
	FooterContactTitle,
	FooterCopyright,
	FooterInfoBlocks,
	FooterQrCode,
	FooterQrCodeImg,
	FooterQrCodeText,
	FooterRightsLine,
	FooterSmallLink,
	FooterSmallLinks,
	FooterSocialMedia,
	FooterWrapper,
	SocialMediaIcon,
	SocialMediaLink
} from './index.styled'
import instagram from '../../img/footer/instagram.png'
import telegram from '../../img/footer/telegram.png'
import viber from '../../img/footer/viber.png'
import facebook from '../../img/footer/fb.png'
import tiktok from '../../img/footer/tik-tok.png'
import QRCode from 'react-qr-code'
import { landingUiUrl } from '../../urls'
import { PageContainer } from '../../style/page/index.styled'

const Footer = () => {
	const handlePhoneClick = () => {
		window.open('tel:+380977957122', '_self')
	}

	return (
		<FooterWrapper>
			<PageContainer>
				<FooterInfoBlocks>
					<FooterQrCode>
						<FooterQrCodeImg>
							<QRCode value="https://josti.onelink.me/NUe6/972z32cy" bgColor="transparent" />
						</FooterQrCodeImg>
						<FooterQrCodeText>Скануйте QR код та завантажуйте додаток Josti!</FooterQrCodeText>
					</FooterQrCode>
					<FooterContact>
						<FooterContactTitle>Консультація</FooterContactTitle>
						<FooterContactPhone onClick={handlePhoneClick}>+380 (97) 795-71-22</FooterContactPhone>
						<FooterContactDescription>Дзвінки приймаються щодня з 8:00 - 22:00</FooterContactDescription>
						<FooterSocialMedia>
							<SocialMediaLink onClick={() => window.open('https://t.me/josticom', '_blank')}>
								<SocialMediaIcon src={telegram} alt="Telegram" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://invite.viber.com/?g2=AQA%2FOKk%2BkK955lF3rvFyhmm%2FneYXFTWqmyBUj93sUz35aHzdT47clzce6b40lYiP', '_blank')}>
								<SocialMediaIcon src={viber} alt="Viber" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://www.instagram.com/josticom/', '_blank')}>
								<SocialMediaIcon src={instagram} alt="Instagram" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://www.facebook.com/josti.com.ua?locale=uk_UA', '_blank')}>
								<SocialMediaIcon src={facebook} alt="Facebook" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://www.tiktok.com/@josti.com.ua?_t=8m6Ubqysgli', '_blank')}>
								<SocialMediaIcon src={tiktok} alt="Tiktok" />
							</SocialMediaLink>
						</FooterSocialMedia>
					</FooterContact>
				</FooterInfoBlocks>
				<FooterRightsLine>
					<FooterSmallLinks>
						<FooterCopyright>2024 Josti. Всі права захищено</FooterCopyright>
						<FooterSmallLink onClick={() => window.open(`${landingUiUrl}/privacy-policy`, '_blank')}>Політика конфіденційності</FooterSmallLink>
						<FooterSmallLink onClick={() => window.open(`${landingUiUrl}/terms-of-use`, '_blank')}>Умови користування</FooterSmallLink>
					</FooterSmallLinks>
				</FooterRightsLine>
			</PageContainer>
		</FooterWrapper>
	)
}

export default Footer
