import authInstance from '../instance'

export const createHost = ({ hostContextId, code }) => {
	return authInstance.post('/hosts', { hostContextId, code })
}

export const updateHostPassword = ({ hostContextId, code, password }) => {
	return authInstance.post('/hosts/password', { hostContextId, code, password })
}

export const loginHost = ({ phone, password }) => {
	return authInstance.post('/hosts/login', { phone, password })
}

export const getHost = hostId => {
	return authInstance.get(`/hosts/${hostId}`)
}

export const updateHost = (hostId, host) => {
	return authInstance.put(`/hosts/${hostId}`, host)
}
