import React from 'react'
import { NewRealtyButtonWrapper } from './index.styled'

const NewRealtyButton = ({ onClick }) => {
	return (
		<NewRealtyButtonWrapper onClick={onClick}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.4335 0.569595C10.2691 0.48644 10.1647 0.463235 9.9887 0.465175C9.71408 0.465183 9.59224 0.521272 9.36596 0.747551C9.20157 0.911941 9.16483 0.964159 9.12228 1.08406C9.08166 1.19817 9.07003 1.99882 9.068 5.14923L9.06595 9.07321L5.15937 9.07333C0.78478 9.07346 1.06907 9.05605 0.75963 9.34228C0.602976 9.48346 0.577833 9.52021 0.51981 9.67106C0.446314 9.86446 0.442438 10.1429 0.513988 10.3267C0.546863 10.4176 0.601011 10.4911 0.740251 10.6303C1.06128 10.9513 0.720902 10.9281 5.15158 10.9299L9.06589 10.9298L9.0677 14.846C9.06757 19.2748 9.04437 18.9344 9.3654 19.2554C9.50464 19.3947 9.57812 19.4488 9.66902 19.4817C9.85274 19.5532 10.1312 19.5494 10.3246 19.4759C10.4755 19.4179 10.5122 19.3927 10.6534 19.2361C10.9396 18.9266 10.9222 19.2109 10.9224 14.8363L10.9225 10.9297L14.8465 10.9277C17.9969 10.9257 18.7975 10.914 18.9116 10.8734C19.0315 10.8309 19.0837 10.7941 19.2481 10.6297C19.4087 10.4692 19.4493 10.4131 19.486 10.3067C19.5479 10.125 19.5441 9.86967 19.4783 9.68402C19.43 9.55057 19.3971 9.50223 19.2482 9.35332C19.1051 9.21021 19.0471 9.17153 18.931 9.12899C18.8169 9.08838 18.0201 9.0768 14.8562 9.0769L10.9225 9.07315L10.9207 5.14143C10.917 1.91561 10.9073 1.18264 10.8648 1.06661C10.801 0.894486 10.5863 0.648882 10.4335 0.569595Z"
					fill="white"
				/>
			</svg>
			Нове оголошення
		</NewRealtyButtonWrapper>
	)
}

export default NewRealtyButton
