import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { PasswordValidation, PasswordValidationLine, PasswordValidationLines, PasswordValidationText, ShowPassword, TextInputError, TextInputInput, TextInputLabel, TextInputPrefix, TextInputWrapper } from './index.styled'
import styled from 'styled-components'
import { TextField } from '@mui/material'

export const TextFieldStyled = styled(TextField)(() => ({
	width: '100%',
	'.MuiInputBase-root': {
		minHeight: '48px',
		borderRadius: '12px',
		paddingRight: '15px',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#CECECE'
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#CECECE'
		},
		'&.Mui-focused': {
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#724FFF'
			}
		},
		'&.Mui-error': {
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#d32f2f'
			}
		}
	},
	'.MuiFormLabel-root': {
		fontSize: '15px',
		lineHeight: 'normal',
		color: '#B4B4B4'
	},
	'.MuiInputBase-input': {
		height: '1em',
		fontSize: '15px',
		lineHeight: 'normal',
		color: '#282828',
		padding: '1px 15px',
		'&.Mui-disabled': {
			'-webkitTextFillColor': 'inherit',
			cursor: 'not-allowed'
		}
	},
	'.MuiOutlinedInput-notchedOutline legend': {
		fontSize: '1em',
		lineHeight: 'normal'
	}
}))

const getPasswordLevel = password => {
	if (!password) {
		return { level: 0, color: null, message: 'Пароль повинен мати мінімум 8 символів' }
	}
	const hasDigit = /\d/.test(password)
	if (password.length < 8 || !hasDigit) {
		return { level: 1, color: '#e43027', message: 'Ненадійний' }
	}
	const hasUpperCase = /[A-Z]/.test(password)
	if (!hasUpperCase) {
		return { level: 2, color: '#ff9700', message: 'Середненький' }
	}
	const hasSpecialChar = /[_!@#$%^&*(),.?":{}|<>]/.test(password)
	if (!hasSpecialChar) {
		return { level: 3, color: '#679b45', message: 'Це вже краще' }
	}
	return { level: 4, color: '#679b45', message: 'Надійний захист' }
}

const CustomTextField = ({ control, label, type, name, required, min, minLength, disabled, prefix, filter, format, withPasswordLevel }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required, min, minLength } })
	const [focused, setFocused] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const value = format ? format(field.value || '') : field.value || ''
	const layout = (focused || field.value ? 'labelUp' : '') + (focused ? ' focused' : '') + (error ? ' error' : '') + (disabled ? ' disabled' : '')
	const typePassword = type === 'password'
	const resultType = typePassword ? (showPassword ? 'text' : 'password') : type
	const passwordLevel = typePassword && getPasswordLevel(value)

	const handleShowPasswordClick = () => {
		setShowPassword(!showPassword)
	}

	const handleChange = e => {
		field.onChange(filter ? filter(e.target.value) : e.target.value)
	}

	return (
		<TextInputWrapper className={layout} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
			<TextInputLabel className={layout}>{label}</TextInputLabel>
			{prefix && <TextInputPrefix>{prefix}</TextInputPrefix>}
			<TextInputInput {...{ label, type: resultType, name, variant: 'outlined', value, onChange: handleChange, ref: field.ref, error, disabled }} className={layout} />
			{typePassword && (
				<ShowPassword onClick={handleShowPasswordClick}>
					{showPassword ? (
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M15.6793 8.00011C15.6793 8.64271 11.746 12.6401 7.99934 12.6401C4.25264 12.6401 0.319336 8.64271 0.319336 8.00011C0.319336 7.35751 4.25264 3.36011 7.99934 3.36011C11.746 3.36011 15.6793 7.35751 15.6793 8.00011ZM10.5595 8.00017C10.5595 9.41401 9.4133 10.5602 7.99945 10.5602C6.5856 10.5602 5.43945 9.41401 5.43945 8.00017C5.43945 6.58632 6.5856 5.44017 7.99945 5.44017C9.4133 5.44017 10.5595 6.58632 10.5595 8.00017ZM7.99973 9.28017C8.70665 9.28017 9.27973 8.70709 9.27973 8.00017C9.27973 7.29324 8.70665 6.72017 7.99973 6.72017C7.2928 6.72017 6.71973 7.29324 6.71973 8.00017C6.71973 8.70709 7.2928 9.28017 7.99973 9.28017Z"
								fill="#B4B4B4"
							/>
						</svg>
					) : (
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M13.8112 2.8675C13.9987 2.68005 13.9987 2.37613 13.8112 2.18868C13.6238 2.00123 13.3199 2.00123 13.1324 2.18868L11.1342 4.18685C10.1368 3.71267 9.06085 3.39209 7.99982 3.39209C4.25312 3.39209 0.319824 7.38949 0.319824 8.03209C0.319824 8.4347 1.86385 10.1543 3.93862 11.3825L2.18841 13.1327C2.00096 13.3201 2.00096 13.624 2.18841 13.8115C2.37586 13.999 2.67978 13.999 2.86724 13.8115L13.8112 2.8675ZM5.86926 9.45183L9.41957 5.90152C9.01328 5.63024 8.52502 5.47209 7.99982 5.47209C6.58597 5.47209 5.43982 6.61824 5.43982 8.03209C5.43982 8.55728 5.59798 9.04554 5.86926 9.45183ZM7.99982 10.5921C7.82273 10.5921 7.64984 10.5741 7.48287 10.5399L5.76911 12.2536C6.49833 12.5132 7.25279 12.6721 7.99982 12.6721C11.7465 12.6721 15.6798 8.67469 15.6798 8.03209C15.6798 7.68181 14.5112 6.33476 12.8411 5.18166L10.5076 7.51513C10.5418 7.6821 10.5598 7.85499 10.5598 8.03209C10.5598 9.44594 9.41367 10.5921 7.99982 10.5921Z"
								fill="#B4B4B4"
							/>
						</svg>
					)}
				</ShowPassword>
			)}
			{error && !withPasswordLevel && <TextInputError>{error.message}</TextInputError>}
			{withPasswordLevel && (
				<PasswordValidation>
					<PasswordValidationLines>
						<PasswordValidationLine fill={passwordLevel.level >= 1 ? passwordLevel.color : ''} />
						<PasswordValidationLine fill={passwordLevel.level >= 2 ? passwordLevel.color : ''} />
						<PasswordValidationLine fill={passwordLevel.level >= 3 ? passwordLevel.color : ''} />
						<PasswordValidationLine fill={passwordLevel.level >= 4 ? passwordLevel.color : ''} />
					</PasswordValidationLines>
					<PasswordValidationText>{error?.message || passwordLevel.message}</PasswordValidationText>
				</PasswordValidation>
			)}
		</TextInputWrapper>
	)
}

export default CustomTextField
