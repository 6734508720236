import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const CooperationWrapper = styled.div`
	display: flex;
	background: linear-gradient(138deg, #e4e3fa -20%, #724fff 100%);
	min-height: 178px;
	border-radius: 12px;
	position: relative;
	margin-top: 133px;

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 50px;
	}

	@media screen and (max-width: ${theme.point520}) {
		flex-direction: column;
	}
`

export const CooperationImage = styled.div`
	position: relative;
	width: 50%;

	> img {
		width: 226px;
		height: 231px;
		position: absolute;
		bottom: 0;
		left: 100px;
	}

	@media screen and (max-width: ${theme.point820}) {
		> img {
			left: 50px;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		> img {
			left: 25px;
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		display: none;
	}
`

export const CooperationInfo = styled.div`
	padding-right: 25px;
	padding-bottom: 25px;
	box-sizing: border-box;
	width: 50%;

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
		padding: 0 25px 25px 25px;
	}
`

export const CooperationTitle = styled.div`
	margin-top: 25px;
	font-size: 20px;
	color: #ffffff;
	font-weight: 400;
`

export const CooperationText = styled.div`
	margin-top: 15px;
	font-size: 15px;
	line-height: 23px;
	color: #ffffff;
	font-weight: 400;
`

export const CooperationLink = styled.div`
	margin-top: 25px;
	font-size: 15px;
	line-height: 18px;
	color: #ffffff;
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;

	> svg {
		width: 12px;
		height: 12px;
	}
`

export const CooperationCross = styled.div`
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
`
