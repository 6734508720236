import React, { useState } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import { LoginFormBlock, LoginFormButton, LoginFormButtonText, LoginFormFooter, LoginFormForgot, LoginFormInputs, LoginFormNoProfile, LoginFormSubtitle, LoginFormTitle, LoginImg, LoginInner, LoginPageWrapper, LoginTitle } from './index.styled'
import background from '../../img/host/work.jpg'
import { CircularProgress } from '@mui/material'
import { setAccessToken } from '../../store/auth/reducer'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import CustomTextField from '../../component/Form/CustomTextField'
import { useForm } from 'react-hook-form'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'
import { loginHost } from '../../api/auth/host'

const LoginPage = () => {
	const { handleSubmit, control, setError } = useForm()
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isSmallTablet = useWindowBreakPoint(theme.point520)

	const handlePhoneFilter = value => {
		if (!value) {
			return value
		}
		let filteredValue = value.replaceAll(/[^0-9]/g, '')
		if (filteredValue.length > 9) {
			filteredValue = filteredValue.slice(0, 9)
		}
		return filteredValue
	}

	const handlePhoneFormat = value => {
		let formattedValue = ''
		if (value.length > 0) {
			formattedValue += '(' + value.slice(0, 2)
		}
		if (value.length >= 3) {
			formattedValue += ') ' + value.slice(2, 5)
		}
		if (value.length >= 6) {
			formattedValue += '-' + value.slice(5, 7)
		}
		if (value.length >= 8) {
			formattedValue += '-' + value.slice(7, 9)
		}
		return formattedValue
	}

	const handleLoginClick = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		try {
			const res = await loginHost({ phone: '380' + data.phone, password: data.password })
			dispatch(setAccessToken(res.data.token))
			navigate('/realties')
		} catch (error) {
			if (error.response?.data?.code === 'host_not_found') {
				setError('phone', { type: 'custom', message: 'Номер телефону не зареєстрований' })
			} else if (error.response?.data?.code === 'invalid_phone_password') {
				setError('password', { type: 'custom', message: 'Номер телефону або пароль введені не вірно' })
			}
		}
		setLoading(false)
	}

	return (
		<PageWrapper>
			<Header />
			<LoginPageWrapper>
				<LoginImg src={background} />
				<PageContainer className={isSmallTablet ? 'disabled' : ''}>
					<LoginInner>
						<LoginTitle>Співпрацюйте з Josti</LoginTitle>
						<LoginFormBlock onSubmit={handleSubmit(handleLoginClick)}>
							<LoginFormTitle>Увійти в кабінет</LoginFormTitle>
							<LoginFormSubtitle>Привіт! Скоріше заходьте в свій кабінет та заробляйте разом з нами</LoginFormSubtitle>
							<LoginFormInputs>
								<CustomTextField control={control} label="Номер телефону" name="phone" prefix="+380" filter={handlePhoneFilter} format={handlePhoneFormat} required="Номер телефону обовʼязковий" minLength={{ value: 9, message: 'Номер телефону введений не повністю' }} />
								<CustomTextField control={control} label="Пароль" name="password" type="password" required="Пароль обовʼязковий" />
							</LoginFormInputs>
							<LoginFormFooter>
								<div />
								<LoginFormForgot to="/password-recovery">Забули пароль?</LoginFormForgot>
							</LoginFormFooter>
							<LoginFormButton>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <LoginFormButtonText>Увійти</LoginFormButtonText>}</LoginFormButton>
							<LoginFormNoProfile>
								Немає профілю? <NavLink to="/registration">Зареєструйтеся</NavLink>
							</LoginFormNoProfile>
						</LoginFormBlock>
					</LoginInner>
				</PageContainer>
			</LoginPageWrapper>
			<Footer />
		</PageWrapper>
	)
}

export default LoginPage
