import styled from 'styled-components'
import { theme } from '../../style/theme'

export const FooterWrapper = styled.footer`
	width: 100%;
	background: #ffffff;
	padding: 25px 0 0;
	margin-top: 100px;
`

export const FooterInfoBlocks = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
	}
`

export const FooterQrCode = styled.div`
	width: 280px;
	display: flex;
	align-items: center;
	gap: 15px;
	background: #f5f4f9;
	padding: 15px;
	border-radius: 12px;

	@media screen and (max-width: ${theme.point720}) {
		display: none;
	}
`

export const FooterQrCodeImg = styled.div`
	width: 112px;
	height: 112px;
	padding: 8px;
	flex-shrink: 0;

	> svg {
		width: 100%;
		height: 100%;
	}
`

export const FooterQrCodeText = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

export const FooterContact = styled.div``

export const FooterContactTitle = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const FooterContactPhone = styled.div`
	color: #724fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 15px;
`

export const FooterContactDescription = styled.div`
	width: 149px;
	color: #b4b4b4;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 10px;
`

export const FooterRightsLine = styled.div`
	height: 68px;
	margin-top: 25px;
	border-top: 1px solid #cecece7f;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${theme.point720}) {
		height: auto;
		padding: 25px 0;
	}
`

export const FooterSocialMedia = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 15px;
`

export const SocialMediaLink = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 10px;
	background-color: #f5f4f9;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
`

export const SocialMediaIcon = styled.img`
	width: 14px;
	height: 14px;
`

export const FooterSmallLinks = styled.div`
	display: flex;
	gap: 50px;
	align-items: center;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}
`

export const FooterCopyright = styled.div`
	color: #2828287f;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 12px;
		color: #b4b4b4;
		font-weight: 400;
	}
`

export const FooterSmallLink = styled.div`
	color: #282828;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 12px;
		color: #b4b4b4;
		font-weight: 400;
	}
`
