import styled from 'styled-components'
import { theme } from '../../style/theme'

export const BalancePageBlocks = styled.div`
	margin-top: 15px;
	display: flex;
	align-items: flex-start;
	gap: 15px;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column-reverse;
	}
`

export const TransactionsBlock = styled.div`
	background: #ffffff;
	border-radius: 12px;
	padding: 25px;
	width: 100%;

	@media screen and (max-width: ${theme.point520}) {
		padding: 15px;
	}
`

export const TransactionsTitle = styled.div`
	font-size: 15px;
	font-weight: 500;
	color: #282828;
`

export const Transactions = styled.div`
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	gap: 5px;
`

export const TransactionItem = styled.div`
	min-height: 48px;
	background: #f5f5f5;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 5px 25px;

	@media screen and (max-width: ${theme.point520}) {
		flex-wrap: wrap;
		min-height: 58px;
		padding: 5px 15px;
	}
`

export const TransactionNumber = styled.div`
	font-size: 13px;
	font-weight: 500;
	color: #282828;
`

export const TransactionTitle = styled.div`
	font-size: 13px;
	font-weight: 500;
	color: #282828;
`

export const TransactionDate = styled.div`
	font-size: 13px;
	font-weight: 400;
	color: #282828;
`

export const TransactionAmount = styled.div`
	font-size: 15px;
	font-weight: 500;
	color: #679b45;
	flex-shrink: 0;
`

export const BalanceBlock = styled.div`
	background: #ffffff;
	border-radius: 12px;
	padding: 25px;
	width: 310px;
	flex-shrink: 0;

	@media screen and (max-width: ${theme.point520}) {
		padding: 15px;
		width: 100%;
	}
`

export const BalanceTitle = styled.div`
	font-size: 15px;
	font-weight: 500;
	color: #282828;
`

export const BalanceValue = styled.div`
	margin-top: 15px;
	font-size: 25px;
	font-weight: 500;
	color: #282828;
`

export const BalanceButton = styled.div`
	margin-top: 40px;
	width: auto;
	height: 48px;
	padding: 5px 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 12px;
	background: #724fff;
	cursor: pointer;
	box-shadow: none;
	border: none;
	outline: none;
	user-select: none;

	&.disabled {
		background: #cecece;
		cursor: not-allowed;
	}

	&:hover {
		opacity: 0.5;
	}
`
