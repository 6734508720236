import { getAppEnvironment } from './helper'

const internalEnv = getAppEnvironment() === 'live' ? '' : window.REACT_APP_ENVIRONMENT + '.'

export const authServiceUrl = `https://${internalEnv}auth.josti.com.ua`

export const realtyServiceUrl = `https://${internalEnv}realty.josti.com.ua`

export const bookingServiceUrl = `https://${internalEnv}booking.josti.com.ua`

export const landingUiUrl = `https://${internalEnv}josti.com.ua`
