import React, { useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useController } from 'react-hook-form'
import { ImageButton, ImageInputButtonWrapper, ImageInputTitle, ImageItemCross, ImageItemWrapper, ImagesWrapper, ImageWrapper, MainImageButton, MarkMainImageButton } from './index.styled'
import { createImage, getPresignedImage } from '../../../api/realty/image'

export const performFileUpload = async ({ file }) => {
	const nameSplit = file.name.split('.')
	const { data } = await getPresignedImage(nameSplit[nameSplit.length - 1])
	const {
		data: { secure_url }
	} = await createImage(file, data)
	return secure_url
}

const ImageInput = ({ name, control }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, defaultValue: [], rules: { required: true, validate: v => v?.length >= 3 } })
	const imageInput = useRef(null)
	const [loading, setLoading] = useState(false)

	let handleUploadClick = () => {
		if (loading) {
			return
		}
		imageInput.current.click()
	}

	const handleChange = async e => {
		setLoading(true)
		try {
			const files = e.target.files
			const savedImages = []
			for (let file of Array.from(files)) {
				const savedImage = await performFileUpload({ file })
				savedImages.push(savedImage)
			}
			field.onChange([...field.value, ...savedImages])
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const deleteImage = imgSrc => {
		field.onChange(field.value.filter(img => img !== imgSrc))
	}

	const markMainImage = imgSrc => {
		const images = [...field.value]
		const index = images.indexOf(imgSrc)
		if (index !== -1) {
			images.splice(index, 1)
			images.unshift(imgSrc)
		}
		field.onChange(images)
	}

	return (
		<>
			{!!field.value?.length && (
				<ImagesWrapper>
					{field.value?.map((img, i) => (
						<ImageItemWrapper key={img}>
							<ImageWrapper key={i}>
								<img src={img} alt="img-url" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
							</ImageWrapper>
							<ImageItemCross className="cross" onClick={() => deleteImage(img)}>
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="20" cy="20" r="20" fill="#724FFF" />
									<path
										d="M13.6413 13.0283C13.4662 13.0858 13.376 13.1432 13.2529 13.269C13.0587 13.4632 13.0122 13.589 13.0122 13.909C13.0122 14.1415 13.0232 14.2044 13.0779 14.3193C13.1299 14.4287 13.6878 15.0031 15.914 17.2322L18.6872 20.0083L15.925 22.7707C12.8317 25.8641 13.0205 25.6508 13.004 26.072C12.9931 26.2826 13.0013 26.3264 13.0669 26.4741C13.1517 26.6628 13.3459 26.8625 13.5264 26.9418C13.6139 26.9828 13.7042 26.9965 13.9011 26.9965C14.3551 26.9965 14.098 27.2208 17.2322 24.0891L20 21.3211L22.7705 24.0891C25.902 27.2208 25.6449 26.9965 26.0989 26.9965C26.2958 26.9965 26.3861 26.9828 26.4736 26.9418C26.6541 26.8625 26.8483 26.6628 26.9331 26.4741C26.9987 26.3264 27.0069 26.2826 26.996 26.072C26.9795 25.6508 27.1683 25.8641 24.075 22.7707L21.3128 20.0083L24.086 17.2322C26.3122 15.0031 26.8701 14.4287 26.9221 14.3193C26.9768 14.2044 26.9878 14.1415 26.9878 13.909C26.9878 13.682 26.9768 13.6136 26.9276 13.5124C26.8428 13.3401 26.6596 13.1623 26.4818 13.0776C26.3532 13.0174 26.2958 13.0064 26.0852 13.0064C25.8828 13.0064 25.8145 13.0201 25.7023 13.0721C25.5929 13.1241 25.0213 13.6793 22.7842 15.9166L20 18.6955L17.2186 15.9166C14.9349 13.6383 14.4098 13.1268 14.2977 13.0748C14.1308 12.9982 13.8054 12.9764 13.6413 13.0283Z"
										fill="white"
									/>
								</svg>
							</ImageItemCross>
							{i === 0 ? (
								<MainImageButton type="button">Головне фото</MainImageButton>
							) : (
								<MarkMainImageButton type="button" className="mark-main" onClick={() => markMainImage(img)}>
									Зробити головним
								</MarkMainImageButton>
							)}
						</ImageItemWrapper>
					))}
				</ImagesWrapper>
			)}

			<ImageInputButtonWrapper>
				<input ref={imageInput} name="images" accept=".jpg,.jpeg,.png,.svg,.webp,.gif" onChange={handleChange} id="file-upload" type="file" multiple />
				<ImageButton type="button" onClick={handleUploadClick} variant={field.value?.length ? 'outlined' : 'contained'} className={loading ? 'disabled' : '' + (error ? 'error' : '')}>
					{loading ? (
						<>
							Завантаження... <CircularProgress color="inherit" sx={{ padding: '10px' }} />
						</>
					) : (
						'Завантажити фото'
					)}
				</ImageButton>
				<ImageInputTitle className={error ? 'error' : ''}>Додайте не менше 3-х фотографій об’єкту</ImageInputTitle>
			</ImageInputButtonWrapper>
		</>
	)
}

export default ImageInput
