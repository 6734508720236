import React, { useEffect, useState } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import TabHeader from '../../component/TabHeader'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import { useNavigate } from 'react-router-dom'
import RealtiesTable from '../MyRealtiesPage/component/RealtiesTable'
import { useDispatch, useSelector } from 'react-redux'
import { getRealtiesThunk } from '../../store/realty/thunk'
import { deleteRealty, updateRealty } from '../../api/realty/realty'
import AskModal from '../../component/AskModal'

const ArchivesPage = () => {
	const realties = useSelector(state => state.realty.realties)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [realtyToDelete, setRealtyToDelete] = useState()
	const [realtyToUnArchive, setRealtyToUnArchive] = useState()

	const loadRealties = async () => {
		dispatch(getRealtiesThunk(null, true, 0, 100))
	}

	useEffect(() => {
		loadRealties()
	}, [])

	const handleBack = () => {
		navigate('/realties')
	}

	// const handleOpenDeleteModal = realty => {
	// 	setRealtyToDelete(realty)
	// }

	const handleCloseDeleteModal = () => {
		setRealtyToDelete(null)
	}

	const handleConfirmDelete = async () => {
		await deleteRealty(realtyToDelete._id)
		await loadRealties()
		handleCloseDeleteModal()
	}

	const handleOpenUnArchiveModal = realty => {
		setRealtyToUnArchive(realty)
	}

	const handleCloseUnArchiveModal = () => {
		setRealtyToUnArchive(null)
	}

	const handleConfirmUnArchive = async () => {
		await updateRealty(realtyToUnArchive._id, { ...realtyToUnArchive, archived: false })
		await loadRealties()
		handleCloseUnArchiveModal()
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<TabHeader
					back={{
						title: 'До ваших оголошень',
						onClick: handleBack
					}}
					title="Архів"
					tabs={null}
				/>
				<RealtiesTable
					realties={realties}
					buttons={[
						// { title: 'Видалити', onClick: handleOpenDeleteModal, icon: basket },
						{ title: 'Відновити оголошення', onClick: handleOpenUnArchiveModal, type: 'filled' }
					]}
				/>
				<AskModal isOpen={!!realtyToDelete} title="Видалити це оголошення?" text="Після підтвердження це оголошення буде повністю видалено" handleClose={handleCloseDeleteModal} handleConfirm={handleConfirmDelete} />
				<AskModal isOpen={!!realtyToUnArchive} title="Повернути це оголошення з архіву?" text="Після підтвердження це оголошення потрапить у додаток" handleClose={handleCloseUnArchiveModal} handleConfirm={handleConfirmUnArchive} />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default ArchivesPage
