import { City } from '../../../../MyRealtiesPage/helper'

export const cities = [
	{ value: 'kiev', label: 'Київ' },
	{ value: 'lviv', label: 'Львів' },
	{ value: 'kharkiv', label: 'Харків' },
	{ value: 'odesa', label: 'Одеса' },
	{ value: 'dnipro', label: 'Дніпро' },
	{ value: 'ivano_frankivsk', label: 'Івано-Франківськ' }
]

export const currencies = [
	{ label: 'гривень', value: 'UAH' },
	{ label: 'доларів', value: 'USD' }
]

export const metroStations = [
	// Kiev
	{ city: City.kiev.id, branch: null, label: 'Немає', ruLabel: 'Нет', value: 'NO_METRO' },
	{ city: City.kiev.id, branch: 'M1', label: 'Академмістечко', ruLabel: 'Академгородок', value: 'AKADEMMISTECHKO' },
	{ city: City.kiev.id, branch: 'M1', label: 'Арсенальна', ruLabel: 'Арсенальная', value: 'ARSENALNA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Берестейська', ruLabel: 'Берестейская', value: 'BERESTEISKA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Бориспільська', ruLabel: 'Бориспольская', value: 'BORYSPILSKA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Васильківська', ruLabel: 'Васильковская', value: 'VASYLKIWSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Вокзальна', ruLabel: 'Вокзальная', value: 'VOKZALNA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Видубичі', ruLabel: 'Выдубичи', value: 'VYDUBYCHI' },
	{ city: City.kiev.id, branch: 'M3', label: 'Вирлиця', ruLabel: 'Вырлица', value: 'VYRLYTSIA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Виставковий центр', ruLabel: 'Выставочный центр', value: 'VYSTAVKOVYI_TSENTR' },
	{ city: City.kiev.id, branch: 'M2', label: 'Героїв Дніпра', ruLabel: 'Героев Днепра', value: 'HEROIV_DNIPRA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Гідропарк', ruLabel: 'Гидропарк', value: 'HIDROPARK' },
	{ city: City.kiev.id, branch: 'M2', label: 'Голосіївська', ruLabel: 'Голосеевская', value: 'HOLOSIIVSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Дарниця', ruLabel: 'Дарница', value: 'DARNYTSIA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Деміївська', ruLabel: 'Демиевская', value: 'DEMIIVSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Дніпро', ruLabel: 'Днепр', value: 'DNIPRO' },
	{ city: City.kiev.id, branch: 'M3', label: 'Дорогожичі', ruLabel: 'Дорогожичи', value: 'DOROHOZHYCHI' },
	{ city: City.kiev.id, branch: 'M3', label: 'Дружби Народів', ruLabel: 'Дружбы Народов', value: 'DRUZHBY_NARODIV' },
	{ city: City.kiev.id, branch: 'M1', label: 'Житомирська', ruLabel: 'Житомирская', value: 'ZHYTOMYRSKA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Золоті Ворота', ruLabel: 'Золотые Ворота', value: 'ZOLOTI_VOROTA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Іподром', ruLabel: 'Ипподром', value: 'IPODROM' },
	{ city: City.kiev.id, branch: 'M3', label: 'Кловська', ruLabel: 'Кловская', value: 'KLOVSKA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Контрактова Площа', ruLabel: 'Контрактовая Площадь', value: 'KONTRAKTOVA_PLOSCHA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Лівобережна', ruLabel: 'Левобережная', value: 'LIVOBEREZHNA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Лісова', ruLabel: 'Лесная', value: 'LISOVA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Лук`янівська', ruLabel: 'Лукьяновка', value: 'LUKYANIVSKA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Либідська', ruLabel: 'Лыбидская', value: 'LYBIDSKA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Майдан Незалежності', ruLabel: 'Майдан Независимости', value: 'MAIDAN_NEZALEZHNOSTI' },
	{ city: City.kiev.id, branch: 'M2', label: 'Мінська', ruLabel: 'Минская', value: 'MINSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Нивки', ruLabel: 'Нивки', value: 'NYVKY' },
	{ city: City.kiev.id, branch: 'M2', label: 'Оболонь', ruLabel: 'Оболонь', value: 'OBOLON' },
	{ city: City.kiev.id, branch: 'M2', label: 'Олімпійська', ruLabel: 'Олимпийская', value: 'OLIMPIISKA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Осокорки', ruLabel: 'Осокорки', value: 'OSOKORKY' },
	{ city: City.kiev.id, branch: 'M3', label: 'Палац Спорту', ruLabel: 'Дворец Спорта', value: 'PALATS_SPORTU' },
	{ city: City.kiev.id, branch: 'M2', label: 'Палац Україна', ruLabel: 'Дворец Украина', value: 'PALATS_UKRAYINA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Петрівка', ruLabel: 'Петровка', value: 'PETRIVKA' },
	{ city: City.kiev.id, branch: 'M3', label: 'Печерська', ruLabel: 'Печерская', value: 'PECHERSKA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Площа Льва Толстого', ruLabel: 'Площадь Льва Толстого', value: 'PLOSCHA_LVA_TOLSTOHO' },
	{ city: City.kiev.id, branch: 'M3', label: 'Позняки', ruLabel: 'Позняки', value: 'POZNYAKI' },
	{ city: City.kiev.id, branch: 'M1', label: 'Політехнічний Інститут', ruLabel: 'Политехнический Институт', value: 'POLITEKHNICHNYI_INSTYTUT' },
	{ city: City.kiev.id, branch: 'M2', label: 'Поштова Площа', ruLabel: 'Почтовая Площадь', value: 'POSHTOVA_PLOSHCHA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Святошин', ruLabel: 'Святошино', value: 'SVYATOSHYN' },
	{ city: City.kiev.id, branch: 'M3', label: 'Славутич', ruLabel: 'Славутич', value: 'SLAVUTYCH' },
	{ city: City.kiev.id, branch: 'M3', label: 'Сирець', ruLabel: 'Сирец', value: 'SYRETS' },
	{ city: City.kiev.id, branch: 'M2', label: 'Тараса Шевченка', ruLabel: 'Тараса Шевченка', value: 'TARASA_SHEVCHENKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Театральна', ruLabel: 'Театральная', value: 'TEATRALNA' },
	{ city: City.kiev.id, branch: 'M2', label: 'Теремки', ruLabel: 'Теремки', value: 'TEREMKY' },
	{ city: City.kiev.id, branch: 'M1', label: 'Університет', ruLabel: 'Университет', value: 'UNIVERSITET' },
	{ city: City.kiev.id, branch: 'M3', label: 'Харківська', ruLabel: 'Харьковская', value: 'KHARKIVSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Хрещатик', ruLabel: 'Крещатик', value: 'KHRESCHATYK' },
	{ city: City.kiev.id, branch: 'M3', label: 'Червоний Хутір', ruLabel: 'Красный Хутор', value: 'CHERVONIY_KHUTIR' },
	{ city: City.kiev.id, branch: 'M1', label: 'Чернігівська', ruLabel: 'Черниговская', value: 'CHERNIHIVSKA' },
	{ city: City.kiev.id, branch: 'M1', label: 'Шулявська', ruLabel: 'Шулявская', value: 'SHULYAVSKA' },

	// Kharkiv
	{ city: City.kharkiv.id, branch: null, label: 'Немає', ruLabel: 'Нет', value: 'NO_METRO' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Холодна гора', value: 'KHOLODNA_HORA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Вокзальна', value: 'VOKZALNA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Центральний ринок', value: 'CENTRALNYY_RYNOK' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Майдан Конституції', value: 'MAIDAN_KONSTYTUTSII' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Проспект Гагаріна', value: 'PROSPEKT_GAGARINA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Спортивна', value: 'SPORTYVNA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Завод імені Малишева', value: 'ZAVOD_IMENI_MALYSHEVA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Турбоатом', value: 'TURBOATOM' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Палац Спорту', value: 'PALATS_SPORTU' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Армійська', value: 'ARMIISKA' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Імені О.С. Масельського', value: 'IMENI_OS_MASELSKOHO' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Тракторний завод', value: 'TRAKTORNYI_ZAVOD' },
	{ city: City.kharkiv.id, branch: 'M1', label: 'Індустріальна', value: 'INDUSTRIALNA' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Героїв Праці', value: 'HEROIV_PRATSI' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Студентська', value: 'STUDENTSKA' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Академіка Павлова', value: 'AKADEMIKA_PAVLOVA' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Академіка Барабашова', value: 'AKADEMIKA_BARABASHOVA' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Київська', value: 'KYIVSKA' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Ярослава Мудрого', value: 'YAROSLAVA_MUDROHO' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Університет', value: 'UNIVERSYTET' },
	{ city: City.kharkiv.id, branch: 'M2', label: 'Історичний музей', value: 'ISTORYCHNYI_MUZEY' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Перемога', value: 'PEREMOHA' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Олексіївська', value: 'OLEKSIIVSKA' },
	{ city: City.kharkiv.id, branch: 'M3', label: '23 Серпня', value: '23_SERPNYA' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Ботанічний сад', value: 'BOTANICHNYY_SAD' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Наукова', value: 'NAUKOVA' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Держпром', value: 'DERZHPRROM' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Архітектора Бекетова', value: 'ARKHITEKTORA_BEKETOVA' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Захисників України', value: 'ZAKHYSNYKIV_UKRAYINY' },
	{ city: City.kharkiv.id, branch: 'M3', label: 'Метробудівників', value: 'METROBUDIVNYKIV' }
]

export const residences = [
	{ city: City.kiev.id, label: 'Rybalsky', value: 'Rybalsky' },
	{ city: City.kiev.id, label: 'Skyline', value: 'Skyline' },
	{ city: City.kiev.id, label: 'Світлопарк', value: 'Світлопарк' },
	{ city: City.kiev.id, label: 'Щасливий', value: 'Щасливий' },
	{ city: City.kiev.id, label: 'Podil Plaza', value: 'Podil Plaza' },
	{ city: City.kiev.id, label: 'Новопечерска вежа', value: 'Новопечерска вежа' },
	{ city: City.kiev.id, label: 'Central Park', value: 'Central Park' },
	{ city: City.kiev.id, label: 'Crystal Parl', value: 'Crystal Parl' },
	{ city: City.kiev.id, label: 'Crystal Hills', value: 'Crystal Hills' },
	{ city: City.kiev.id, label: 'Артемівський', value: 'Артемівський' },
	{ city: City.kiev.id, label: 'Diamond Hills', value: 'Diamond Hills' },
	{ city: City.kiev.id, label: 'Панорама на Печерську', value: 'Панорама на Печерську' },
	{ city: City.kiev.id, label: 'Richmond', value: 'Richmond' },
	{ city: City.kiev.id, label: 'Підвисоцький', value: 'Підвисоцький' },
	{ city: City.kiev.id, label: 'Brooklyn', value: 'Brooklyn' },
	{ city: City.kiev.id, label: 'Park Avenue', value: 'Park Avenue' },
	{ city: City.kiev.id, label: 'Park Avenue VIP', value: 'Park Avenue VIP' },
	{ city: City.kiev.id, label: 'Медовий', value: 'Медовий' },
	{ city: City.kiev.id, label: 'Південний Кварлат', value: 'Південний Кварлат' },
	{ city: City.kiev.id, label: 'Victory V', value: 'Victory V' },
	{ city: City.kiev.id, label: 'Лідер', value: 'Лідер' },
	{ city: City.kiev.id, label: 'Cardinal', value: 'Cardinal' },
	{ city: City.kiev.id, label: 'Tetris Hall', value: 'Tetris Hall' },
	{ city: City.kiev.id, label: 'Chealsea Tower', value: 'Chealsea Tower' },
	{ city: City.kiev.id, label: 'Alter Ego', value: 'Alter Ego' },
	{ city: City.kiev.id, label: 'Нивки Gарк', value: 'Нивки Gарк' },
	{ city: City.kiev.id, label: 'Салют', value: 'Салют' },
	{ city: City.kiev.id, label: 'Кадетський гай', value: 'Кадетський гай' },
	{ city: City.kiev.id, label: 'Sky Avenue', value: 'Sky Avenue' },
	{ city: City.kiev.id, label: 'Sherwood', value: 'Sherwood' },
	{ city: City.kiev.id, label: 'Малахіт', value: 'Малахіт' },
	{ city: City.kiev.id, label: 'UNIT City', value: 'UNIT City' },
	{ city: City.kiev.id, label: 'Royal Tower', value: 'Royal Tower' },
	{ city: City.kiev.id, label: 'NVER', value: 'NVER' },
	{ city: City.kiev.id, label: 'Boston', value: 'Boston' },
	{ city: City.kiev.id, label: 'UNO', value: 'UNO' },
	{ city: City.kiev.id, label: 'Manhattan City', value: 'Manhattan City' },
	{ city: City.kiev.id, label: 'DOCK32', value: 'DOCK32' },
	{ city: City.kiev.id, label: 'Каховський', value: 'Каховський' },
	{ city: City.kiev.id, label: 'Альпійський', value: 'Альпійський' },
	{ city: City.kiev.id, label: 'Династія', value: 'Династія' },
	{ city: City.kiev.id, label: 'Смарагдовий', value: 'Смарагдовий' },
	{ city: City.kiev.id, label: 'Адамант', value: 'Адамант' },
	{ city: City.kiev.id, label: 'Олександрія', value: 'Олександрія' },
	{ city: City.kiev.id, label: 'Jack House', value: 'Jack House' },
	{ city: City.kiev.id, label: 'Зарічний', value: 'Зарічний' },
	{ city: City.kiev.id, label: 'Славутич', value: 'Славутич' },
	{ city: City.kiev.id, label: 'River Stone', value: 'River Stone' },
	// eslint-disable-next-line quotes
	{ city: City.kiev.id, label: "Рев'єра", value: "Рів'єра" },
	// eslint-disable-next-line quotes
	{ city: City.kiev.id, label: "Сонячна рев'єра", value: "Сонячна рев'єра" },
	{ city: City.kiev.id, label: 'Сонячна брама', value: 'Сонячна брама' },
	{ city: City.kiev.id, label: 'Carnegie Tower', value: 'Carnegie Tower' },
	{ city: City.kiev.id, label: 'Signature', value: 'Signature' },
	{ city: City.kiev.id, label: 'Файна таун', value: 'Файна таун' },
	{ city: City.kiev.id, label: 'Республіка', value: 'Республіка' },
	{ city: City.kiev.id, label: 'Комфорт таун', value: 'Комфорт таун' },
	{ city: City.kiev.id, label: 'Комфорт сіті', value: 'Комфорт сіті' },
	{ city: City.kiev.id, label: 'Мегасіті', value: 'Мегасіті' },
	{ city: City.kiev.id, label: 'Лебединий', value: 'Лебединий' },
	{ city: City.kiev.id, label: 'Олександрівський', value: 'Олександрівський' },
	{ city: City.kiev.id, label: 'Квартет', value: 'Квартет' },
	{ city: City.kiev.id, label: 'Ok Land', value: 'Ok Land' },
	{ city: City.kiev.id, label: 'Time', value: 'Time' },
	{ city: City.kiev.id, label: 'Пори року', value: 'Пори року' },
	{ city: City.kiev.id, label: '4 сезона', value: '4 сезона' },
	{ city: City.kiev.id, label: 'Хвиля', value: 'Хвиля' },
	{ city: City.kiev.id, label: 'Pechersky', value: 'Pechersky' },
	{ city: City.kiev.id, label: 'AVENUE 14-16', value: 'AVENUE 14-16' },
	{ city: City.kiev.id, label: 'Новопечерський Двір', value: 'Новопечерський Двір' },
	{ city: City.kiev.id, label: 'Новопечерські Липки', value: 'Новопечерські Липки' },
	{ city: City.kiev.id, label: 'Busov Hill', value: 'Busov Hill' },
	{ city: City.kiev.id, label: 'Regent Hill', value: 'Regent Hill' },
	{ city: City.kiev.id, label: 'Манхеттен', value: 'Манхеттен' },
	{ city: City.kiev.id, label: 'Chicago', value: 'Chicago' },
	{ city: City.kiev.id, label: 'New York', value: 'New York' },
	{ city: City.kiev.id, label: 'Bristol', value: 'Bristol' },
	{ city: City.kiev.id, label: '044', value: '044' },
	{ city: City.kiev.id, label: 'Soho Residence', value: 'Soho Residence' },
	{ city: City.kiev.id, label: 'San Francisco', value: 'San Francisco' },
	{ city: City.kiev.id, label: 'Бульвар фонтанів', value: 'Бульвар фонтанів' },
	{ city: City.kiev.id, label: 'Дипломат Холл', value: 'Дипломат Холл' },
	{ city: City.kiev.id, label: 'Перлина 52', value: 'Перлина 52' },
	{ city: City.kiev.id, label: 'Елегант', value: 'Елегант' },
	{ city: City.kiev.id, label: 'Діамант', value: 'Діамант' },
	{ city: City.kiev.id, label: 'Отрада', value: 'Отрада' },
	{ city: City.kiev.id, label: 'Family Friends', value: 'Family Friends' },
	{ city: City.kiev.id, label: 'Патріотика', value: 'Патріотика' },
	{ city: City.kiev.id, label: 'Нова Англія', value: 'Нова Англія' },
	{ city: City.kiev.id, label: 'Лікоград', value: 'Лікоград' },
	{ city: City.kiev.id, label: 'Львівський квартал', value: 'Львівський квартал' },
	{ city: City.kiev.id, label: 'Ярославів град', value: 'Ярославів град' },
	{ city: City.kiev.id, label: 'Fiord', value: 'Fiord' },
	{ city: City.kiev.id, label: 'Престиж Холл', value: 'Престиж Холл' },
	{ city: City.kiev.id, label: 'Таріян таверс', value: 'Таріян таверс' },
	{ city: City.kiev.id, label: 'Французький квартал 1', value: 'Французький квартал 1' },
	{ city: City.kiev.id, label: 'Французький квартал 2', value: 'Французький квартал 2' },
	{ city: City.kiev.id, label: 'Obolonsky', value: 'Obolonsky' },
	{ city: City.kiev.id, label: 'Паркове місто', value: 'Паркове місто' },
	{ city: City.kiev.id, label: 'Місто квітів', value: 'Місто квітів' },
	{ city: City.kiev.id, label: 'Варшавський Квартал', value: 'Варшавський Квартал' },
	{ city: City.kiev.id, label: 'Караваєві дачі', value: 'Караваєві дачі' },
	{ city: City.kiev.id, label: 'Campus', value: 'Campus' },
	{ city: City.kiev.id, label: 'Elyseum', value: 'Elyseum' },
	{ city: City.kiev.id, label: 'Аристократ', value: 'Аристократ' },
	{ city: City.kiev.id, label: 'L-квартал', value: 'L-квартал' },
	{ city: City.kiev.id, label: 'Козацький', value: 'Козацький' },
	{ city: City.kiev.id, label: 'Крістер град', value: 'Крістер град' },
	{ city: City.kiev.id, label: 'Зелений острів', value: 'Зелений острів' },
	{ city: City.kiev.id, label: 'Зелений острів 2', value: 'Зелений острів 2' },
	{ city: City.kiev.id, label: 'Seven', value: 'Seven' },

	// lviv
	{ city: City.lviv.id, label: 'ЖК Avalon', value: 'ЖК Avalon' },
	{ city: City.lviv.id, label: 'ЖК Велика Британія', value: 'ЖК Велика Британія' },
	{ city: City.lviv.id, label: 'ЖК Щасливий', value: 'ЖК Щасливий' },
	{ city: City.lviv.id, label: 'ЖК Семицвіт', value: 'ЖК Семицвіт' },
	{ city: City.lviv.id, label: 'ЖК Національний', value: 'ЖК Національний' },
	{ city: City.lviv.id, label: 'ЖК Бейкер Стріт', value: 'ЖК Бейкер Стріт' },
	{ city: City.lviv.id, label: 'ЖК Ріел Сіті', value: 'ЖК Ріел Сіті' },
	{ city: City.lviv.id, label: 'ЖК Америка', value: 'ЖК Америка' },
	{ city: City.lviv.id, label: 'ЖК Сіті', value: 'ЖК Сіті' },
	{ city: City.lviv.id, label: 'ЖК Велет', value: 'ЖК Велет' },

	// kharkiv
	{ city: City.kharkiv.id, label: 'ЖК Миру', value: 'ЖК Миру' },
	{ city: City.kharkiv.id, label: 'ЖК Дім на Набережній', value: 'ЖК Дім на Набережній' },
	{ city: City.kharkiv.id, label: 'ЖК Лісопарковий', value: 'ЖК Лісопарковий' },
	{ city: City.kharkiv.id, label: 'ЖК Соляріс', value: 'ЖК Соляріс' },
	{ city: City.kharkiv.id, label: 'ЖК Французький квартал', value: 'ЖК Французький квартал' },
	{ city: City.kharkiv.id, label: 'ЖК Перемога', value: 'ЖК Перемога' },
	{ city: City.kharkiv.id, label: 'ЖК Воробйови гори', value: 'ЖК Воробйови гори' },
	{ city: City.kharkiv.id, label: 'ЖК Манхеттен', value: 'ЖК Манхеттен' },
	{ city: City.kharkiv.id, label: 'ЖК Нова Англія', value: 'ЖК Нова Англія' },
	{ city: City.kharkiv.id, label: 'ЖК Центральний', value: 'ЖК Центральний' },

	// odessa
	{ city: City.odesa.id, label: 'ЖК Альтаїр', value: 'ЖК Альтаїр' },
	{ city: City.odesa.id, label: 'ЖК Аркадійський палац', value: 'ЖК Аркадійський палац' },
	{ city: City.odesa.id, label: 'ЖК Аквамарин', value: 'ЖК Аквамарин' },
	{ city: City.odesa.id, label: 'ЖК Острова', value: 'ЖК Острова' },
	{ city: City.odesa.id, label: 'ЖК Одесский дворик', value: 'ЖК Одесский дворик' },
	{ city: City.odesa.id, label: 'ЖК Апельсин', value: 'ЖК Апельсин' },
	{ city: City.odesa.id, label: 'ЖК Дворянский', value: 'ЖК Дворянский' },
	{ city: City.odesa.id, label: 'ЖК Чудо-город', value: 'ЖК Чудо-город' },
	{ city: City.odesa.id, label: 'ЖК Сапфир', value: 'ЖК Сапфир' },
	{ city: City.odesa.id, label: 'ЖК Французский бульвар', value: 'ЖК Французский бульвар' },

	// dnipro
	{ city: City.dnipro.id, label: 'ЖК Панорама', value: 'ЖК Панорама' },
	{ city: City.dnipro.id, label: 'ЖК Придніпровський', value: 'ЖК Придніпровський' },
	{ city: City.dnipro.id, label: 'ЖК Лазурний', value: 'ЖК Лазурний' },
	{ city: City.dnipro.id, label: 'ЖК Меридіан', value: 'ЖК Меридіан' },
	{ city: City.dnipro.id, label: 'ЖК Новий квартал', value: 'ЖК Новий квартал' },
	{ city: City.dnipro.id, label: 'ЖК Атмосфера', value: 'ЖК Атмосфера' },
	{ city: City.dnipro.id, label: 'ЖК Милосердие', value: 'ЖК Милосердие' },
	{ city: City.dnipro.id, label: 'ЖК Амурские зори', value: 'ЖК Амурские зори' },
	{ city: City.dnipro.id, label: 'ЖК Салют', value: 'ЖК Салют' },
	{ city: City.dnipro.id, label: 'ЖК Вишневий', value: 'ЖК Вишневий' },

	// ivano-fr
	{ city: City.ivano_frankivsk.id, label: 'ЖК Левада', value: 'ЖК Левада' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Містечко Липки', value: 'ЖК Містечко Липки' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Паркова алея', value: 'ЖК Паркова алея' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Велика Британія', value: 'ЖК Велика Британія' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Еверест', value: 'ЖК Еверест' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК River Park', value: 'ЖК River Park' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Сонячний', value: 'ЖК Сонячний' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Крайобраз', value: 'ЖК Крайобраз' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Місто для людей', value: 'ЖК Місто для людей' },
	{ city: City.ivano_frankivsk.id, label: 'ЖК Панорама Плаза', value: 'ЖК Панорама Плаза' }
]

export const amenities = [
	{ label: 'Фен', value: 'hairDryer' },
	{ label: 'Душова кабінка', value: 'showerCabin' },
	{ label: 'Ванна', value: 'bath' },
	{ label: 'Базові зручності', value: 'basic' },
	{ label: 'Засоби прибирання', value: 'cleaningProducts' },
	{ label: 'Праска', value: 'iron' },
	{ label: 'Дошка для прасування', value: 'ironBoard' },
	{ label: 'Пилосос', value: 'vacuumCleaner' },
	{ label: 'Постільна білизна', value: 'linens' },
	{ label: 'Матрац', value: 'mattress' },
	{ label: 'Вішаки', value: 'hangers' },
	{ label: 'Супутникове телебачення', value: 'satelliteTv' },
	{ label: 'Централізоване опалення', value: 'centralHeating' },
	{ label: 'Генератор', value: 'generator' },
	{ label: 'Сигналізація', value: 'alarms' },
	{ label: 'Сейф', value: 'safe' },
	{ label: 'Аптечка першої допомоги', value: 'firstAidKit' },
	{ label: 'Вогнегасник', value: 'extinguisher' },
	{ label: 'Окреме робоче місце', value: 'workplace' },
	{ label: 'Посуд', value: 'dishes' },
	{ label: 'Холодильник', value: 'fridge' },
	{ label: 'Кухонна плита', value: 'stove' },
	{ label: 'Духова шафа', value: 'oven' },
	{ label: 'Мікрохвильова піч', value: 'microwave' },
	{ label: 'Посудомийна машина', value: 'dishwasher' },
	{ label: 'Чайник', value: 'kettle' },
	{ label: 'Кавомашина', value: 'coffeeMachine' },
	{ label: 'Мультиварка', value: 'multicooker' },
	{ label: 'Лоджія або балкон', value: 'balcony' },
	{ label: 'Безкоштовний вуличний паркінг', value: 'parking' },
	{ label: 'Ліфт', value: 'elevator' }
]
