import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormAdditions, FormBlock, FormButton, FormButtons, FormConfirm, FormInputs, FormNumber, FormSubtitle, FormSwitchInputs, FormTitle, RealtyFormWrapper } from './index.styled'
import CustomAutocomplete from '../../../../component/Form/CustomAutocomplete'
import CustomTextField from '../../../../component/Form/CustomTextField'
import CustomMultiAutocomplete from '../../../../component/Form/CustomMultiAutocomplete'
import { amenities, cities, currencies, metroStations, residences } from './constant'
import { Box, CircularProgress } from '@mui/material'
import CustomSwitch from '../../../../component/Form/CustomSwitch'
import CustomRadioGroup from '../../../../component/Form/CustomRadioGroup'
import CustomTextArea from '../../../../component/Form/CustomTextArea'
import ImageInput from '../../../../component/Form/ImageInput'
import TabHeader from '../../../../component/TabHeader'
import { districts } from '../../../MyRealtiesPage/helper'
import CustomCheckbox from '../../../../component/Form/CustomCheckbox'

const filterNumbers = string => {
	if (!string) {
		return ''
	}
	return string.replace(/[^0-9]/g, '')
}

const RealtyForm = ({ initialRealty, onSave }) => {
	const navigate = useNavigate()
	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { isDirty }
	} = useForm()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (initialRealty) {
			reset({ ...initialRealty })
		}
	}, [!initialRealty])

	const handleBack = () => {
		navigate('/realties')
	}

	const handleCancel = () => {
		reset({ ...initialRealty })
	}

	const onSubmit = async values => {
		if (loading) {
			return
		}
		setLoading(true)
		try {
			const data = { ...values, minPrice: parseFloat(values.minPrice) }
			await onSave(data)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error)
		}
		setLoading(false)
	}

	return (
		<>
			<TabHeader
				back={{
					title: 'До ваших оголошень',
					onClick: handleBack
				}}
				title="Нове оголошення"
				tabs={null}
			/>
			<RealtyFormWrapper onSubmit={handleSubmit(onSubmit)}>
				<FormBlock>
					<FormTitle>
						<FormNumber>1</FormNumber>
						Розташування
					</FormTitle>
					<FormInputs>
						<CustomAutocomplete
							control={control}
							name="address.city"
							label="Місто"
							required={true}
							options={cities}
							onValue={v => cities.find(city => city.value === v)?.label || null}
							onChange={(e, value) => {
								return value?.value
							}}
						/>
						<CustomAutocomplete
							control={control}
							name="district"
							label="Район"
							required={watch('address.city') !== 'ivano_frankivsk'}
							options={districts.filter(district => watch('address.city') === district.city)}
							onValue={v => districts.find(district => district.value === v)?.label || null}
							onChange={(e, value) => {
								return value?.value
							}}
						/>
						<CustomTextField control={control} required={true} label="Вулиця" name="address.street" />
						<CustomTextField control={control} label="Номер будинку" name="address.streetNumber" required={true} />
						<CustomAutocomplete
							control={control}
							name="residence"
							label="Житловий комплекс"
							options={residences.filter(residence => watch('address.city') === residence.city)}
							onValue={v => residences.find(residence => residence.value === v)?.label || null}
							onChange={(e, value) => {
								return value?.value || null
							}}
						/>
						<CustomMultiAutocomplete
							id="checkboxes-tags-demo"
							control={control}
							name="metroStations"
							label="Метро"
							placeholder="Станції"
							options={metroStations.filter(metro => watch('address.city') === metro.city)}
							required={false}
							onChange={(e, value) => {
								return value?.map(station => station.value)
							}}
							onValue={v => v?.map(value => metroStations.find(station => station.value === value)) || []}
							defaultValue={[]}
						/>
					</FormInputs>
				</FormBlock>
				<FormBlock>
					<FormTitle>
						<FormNumber>2</FormNumber>
						Основні параметри
					</FormTitle>
					<FormInputs>
						<CustomTextField control={control} label="Ціна" name="minPrice" required={true} min={0} filter={filterNumbers} />
						<CustomAutocomplete
							name="currency"
							control={control}
							label="Валюта"
							required={true}
							options={currencies}
							onValue={v => currencies.find(curr => curr.value === v)?.label || null}
							onChange={(e, value) => {
								return value?.value
							}}
						/>
					</FormInputs>
				</FormBlock>
				<FormBlock>
					<FormTitle>
						<FormNumber>3</FormNumber>
						Інформація про об’єкт
					</FormTitle>
					<FormInputs>
						<CustomTextField control={control} label="Поверхів у будинку" name="additionalInfo.floorsInBuilding" required={true} min={0} filter={filterNumbers} />
						<CustomTextField control={control} label="Поверх" name="additionalInfo.floor" required={true} min={0} filter={filterNumbers} />
						<CustomTextField control={control} label="Площа" name="additionalInfo.size" required={true} min={0} filter={filterNumbers} />
						<CustomTextField control={control} label="Кількість кімнат" name="roomsNumber" required={true} min={0} filter={filterNumbers} />
					</FormInputs>
				</FormBlock>
				<FormBlock>
					<FormTitle>
						<FormNumber>4</FormNumber>
						Зручності
					</FormTitle>
					<FormSubtitle>Основні зручності</FormSubtitle>
					<FormSwitchInputs>
						<CustomSwitch control={control} name="additionalInfo.wifi" label="Wi-Fi" />
						<CustomSwitch control={control} name="additionalInfo.boiler" label="Бойлер" />
						<CustomSwitch control={control} name="additionalInfo.ac" label="Кондиціонер" />
						<CustomSwitch control={control} name="additionalInfo.washMachine" label="Пральна машина" />
						<CustomSwitch control={control} name="additionalInfo.furnished" label="Меблі" />
						<CustomSwitch control={control} name="additionalInfo.tv" label="Телевізор" />
					</FormSwitchInputs>
					<FormSubtitle>Додаткові</FormSubtitle>
					<FormAdditions>
						<CustomMultiAutocomplete
							id="checkboxes-additional-info"
							control={control}
							name="amenities"
							label="В квартирі є"
							placeholder="Зручності"
							options={amenities}
							required={true}
							onChange={(e, values) => {
								const amen = {}
								values.forEach(value => {
									amen[value.value] = true
								})
								return amen
							}}
							onValue={v => amenities.filter(am => !!v?.[am.value]) || []}
						/>
					</FormAdditions>
				</FormBlock>
				<FormBlock>
					<FormTitle>
						<FormNumber>5</FormNumber>
						Правила проживання
					</FormTitle>
					<CustomRadioGroup
						control={control}
						label="Можна з дітьми"
						name="livingRules.children"
						required={true}
						options={[
							{ value: 'NOT_SPECIFIED', label: 'Невідомо' },
							{ value: 'NOT_ALLOWED', label: 'Ні, не можна' },
							{ value: 'ALLOWED', label: 'Так, можна' }
						]}
					/>
					<CustomRadioGroup
						control={control}
						label="Можна з деякими тваринами"
						name="livingRules.animals"
						required={true}
						options={[
							{ value: 'NOT_SPECIFIED', label: 'Невідомо' },
							{ value: 'NOT_ALLOWED', label: 'Ні, не можна' },
							{ value: 'ALLOWED', label: 'Так, можна' }
						]}
					/>
				</FormBlock>
				<FormBlock>
					<FormTitle>
						<FormNumber>6</FormNumber>
						Про житло
					</FormTitle>
					<Box sx={{ marginTop: '25px' }}>
						<CustomTextArea control={control} label="Опис" name="desc" sx={{ width: '100%' }} required={true} multiline />
					</Box>
					{/*<Box sx={{ marginTop: '25px' }}>*/}
					{/*	<CustomTextField control={control} label="Посилання на відеотур" name="videoLink" sx={{ width: '100%', mt: '24px' }} />*/}
					{/*</Box>*/}
					<ImageInput name="images" control={control} />
				</FormBlock>
				<FormBlock className="bottom">
					{!initialRealty && (
						<FormConfirm>
							<CustomCheckbox control={control} name="confirmation" label="Я розумію концепт платформи та підтвреджую, що обʼєкт здається без комісії" required={true} />
						</FormConfirm>
					)}
					<FormButtons>
						<FormButton type="button" className="transparent" onClick={handleCancel} disabled={!isDirty}>
							Відміна
						</FormButton>
						<FormButton className={loading ? 'disabled' : ''}>
							{loading ? (
								<>
									Збереження... <CircularProgress color="inherit" sx={{ padding: '10px' }} />
								</>
							) : (
								'Опублікувати'
							)}
						</FormButton>
					</FormButtons>
				</FormBlock>
			</RealtyFormWrapper>
		</>
	)
}

export default RealtyForm
