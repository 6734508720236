import React, { useState } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import ConfirmPhone from './component/ConfirmPhone'
import EnterPhone from './component/EnterPhone'
import SetNewPassword from './component/SetNewPassword'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'

const RecoveryPage = () => {
	const [hostContext, setHostContext] = useState(false)
	const [hostData, setHostData] = useState({})
	const isSmallTablet = useWindowBreakPoint(theme.point520)

	return (
		<PageWrapper>
			<Header />
			<PageContainer className={isSmallTablet ? 'disabled' : ''}>
				{!hostContext && <EnterPhone {...{ hostData, setHostData, setHostContext }} />}
				{hostContext && !hostData.code && <ConfirmPhone {...{ hostData, setHostData, hostContext, setHostContext }} />}
				{hostContext && hostData.code && <SetNewPassword {...{ hostData, setHostData, hostContext, setHostContext }} />}
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default RecoveryPage
