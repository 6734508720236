import styled from 'styled-components'
import { theme } from '../../style/theme'

export const TabHeaderWrapper = styled.div`
	margin-top: 25px;
	background: #ffffff;
	padding: 25px;
	border-radius: 12px;

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 15px;
		padding: 20px 16px;
	}
`

export const TabHeaderBack = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 25px;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
`

export const TabHeaderBackArrow = styled.div`
	display: flex;

	> svg {
		width: 15px;
		fill: #282828;
	}
`

export const TabHeaderBackTitle = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

export const TabHeaderTitle = styled.div`
	color: #282828;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 20px;
	}
`

export const TabHeaderTabs = styled.div`
	margin-top: 25px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
`

export const TabHeaderTab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
	border-radius: 10px;
	padding: 0 14px;
	border: 1px solid #cecece;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;

	&.active {
		border-color: #724fff;
		color: #724fff;
	}

	&:hover {
		opacity: 0.5;
	}
`
