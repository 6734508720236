import React, { useRef, useState } from 'react'
import { PhoneCodeForm, PhoneCodeInput, PhoneCodeItem, PhoneCodeItems, RecoveryButtonText, RecoveryForm, RecoveryFormBack, RecoveryFormButton, RecoveryFormSubtitle, RecoveryFormTitle, RepeatSmsLink, RepeatSmsText } from '../../index.styled'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { sendHostContextSms, verifyHostContextCode } from '../../../../api/auth/hostContext'

const ConfirmPhone = ({ hostData, setHostData, hostContext, setHostContext }) => {
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)
	const [invalidCode, setInvalidCode] = useState(false)
	const inputRef = useRef(null)

	const buttonDisabled = code?.length !== 4
	const layout = buttonDisabled ? 'disabled' : ''

	const handleBack = () => {
		setHostContext(null)
		setHostData({ ...hostData, code: null })
	}

	const handleCodeChange = async event => {
		event.target.value = event.target.value.replaceAll(/[^0-9]/g, '')
		setCode(event.target.value)
		setInvalidCode(false)
	}

	const handleBlur = async () => {
		inputRef.current.focus()
	}

	const handleRepeatSms = async () => {
		await sendHostContextSms(hostContext?.id)
	}

	const handleConfirmClick = async () => {
		if (buttonDisabled || loading) {
			return
		}
		setLoading(true)
		try {
			await verifyHostContextCode(hostContext?.id, code)
			setHostData({ ...hostData, code: code })
		} catch (error) {
			if (error.response.status === 400) {
				setInvalidCode(true)
			} else {
				toast.error(error.response.data.message)
			}
		}
		setLoading(false)
	}

	const preventDefault = e => {
		e.preventDefault()
	}

	return (
		<RecoveryForm onSubmit={preventDefault}>
			<RecoveryFormBack onClick={handleBack}>
				<svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
					<path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7L16 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM16 7L1 7L1 9L16 9L16 7Z" />
				</svg>
				Повернутися назад
			</RecoveryFormBack>
			<RecoveryFormTitle>Підтвердіть номер телефону</RecoveryFormTitle>
			<RecoveryFormSubtitle>
				Нам потрібно переконатись, що це дійсно ви. Введіть код підтвердження, який ми надіслали на ваш номер телефону <b>+{hostData.resultPhone}</b>
			</RecoveryFormSubtitle>
			<PhoneCodeForm>
				<PhoneCodeItems>
					<PhoneCodeInput ref={inputRef} value={code} type="tel" autoFocus={true} maxLength={4} onChange={handleCodeChange} onBlur={handleBlur} />
					{[0, 1, 2, 3].map(item => (
						<PhoneCodeItem key={`key=${item}`} className={(code.length > item && 'filled') + (invalidCode ? ' error' : '')}>
							{code.substring(item, item + 1)}
						</PhoneCodeItem>
					))}
				</PhoneCodeItems>
				<RepeatSmsText>
					Не отримали SMS? <RepeatSmsLink onClick={handleRepeatSms}>Надіслати повторно</RepeatSmsLink>
				</RepeatSmsText>
				<RecoveryFormButton type="button" className={layout} onClick={handleConfirmClick}>
					{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <RecoveryButtonText>Підтвердити номер</RecoveryButtonText>}
				</RecoveryFormButton>
			</PhoneCodeForm>
		</RecoveryForm>
	)
}

export default ConfirmPhone
