import React, { useEffect, useState } from 'react'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import Header from '../../component/Header'
import { BalanceBlock, BalanceButton, BalancePageBlocks, BalanceTitle, BalanceValue, TransactionAmount, TransactionDate, TransactionItem, TransactionNumber, Transactions, TransactionsBlock, TransactionsTitle, TransactionTitle } from './index.styled'
import Footer from '../../component/Footer'
import TabHeader from '../../component/TabHeader'
import { formatBigPriceWithCents } from '../MyRealtiesPage/helper'
import { getHostBalance } from '../../api/booking/balance'
import { toast } from 'react-toastify'
import PayoutModal from './component/PayoutModal'
import PayoutRequestedModal from './component/PayoutRequestedModal'
import { formatTransactionDate } from './helper'

const BalancePage = () => {
	const [balance, setBalance] = useState({ total: 0, currency: 'UAH' })
	const [transactions, setTransactions] = useState([])
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [requestedModalIsOpen, setRequestedModalIsOpen] = useState(false)

	const payoutDisabled = !balance?.total || balance.total === 0

	useEffect(() => {
		getHostBalance()
			.then(res => {
				setBalance(res.data.balance)
				setTransactions(res.data.transactions)
			})
			.catch(err => toast.error(err.message))
	}, [])

	const handlePayoutClick = () => {
		if (payoutDisabled) {
			return
		}
		setModalIsOpen(true)
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<TabHeader back={null} title="Мої рахунки" tabs={null} />
				<BalancePageBlocks>
					<TransactionsBlock>
						<TransactionsTitle>Останні доходи</TransactionsTitle>
						<Transactions>
							{transactions?.map(transaction => (
								<TransactionItem key={transaction._id}>
									<TransactionNumber>№ {transaction.bookingNumber}</TransactionNumber>
									<TransactionTitle>{transaction.title}</TransactionTitle>
									<TransactionDate>{formatTransactionDate(transaction.createdDate)}</TransactionDate>
									<TransactionAmount>{formatBigPriceWithCents(transaction.total, transaction.currency)}</TransactionAmount>
								</TransactionItem>
							))}
						</Transactions>
					</TransactionsBlock>
					<BalanceBlock>
						<BalanceTitle>Баланс</BalanceTitle>
						<BalanceValue>{formatBigPriceWithCents(balance.total, balance.currency)}</BalanceValue>
						<BalanceButton onClick={handlePayoutClick} className={payoutDisabled ? 'disabled' : ''}>
							Вивести кошти
						</BalanceButton>
					</BalanceBlock>
				</BalancePageBlocks>
			</PageContainer>
			<Footer />
			<PayoutModal {...{ balance, modalIsOpen, setModalIsOpen, setRequestedModalIsOpen }} />
			<PayoutRequestedModal {...{ requestedModalIsOpen, setRequestedModalIsOpen }} />
		</PageWrapper>
	)
}

export default BalancePage
