import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const HeaderHostWrapper = styled.div`
	position: relative;
`

export const HeaderHostImage = styled.div`
	width: 48px;
	height: 48px;
	cursor: pointer;

	> svg {
		width: 100%;
		height: 100%;
	}

	&:hover {
		opacity: 0.5;
	}
`

export const HostModalShadow = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #282828;
	transition: all 0.1s ease-in-out;
	z-index: 10;
	opacity: 0;

	&.active-enter-done {
		opacity: 0.5;
	}
`

export const HostModal = styled.div`
	position: absolute;
	right: 0;
	top: 73px;
	z-index: 11;
	background: #ffffff;
	padding: 13px 25px 25px;
	width: 310px;
	border-radius: 12px;
	opacity: 0;
	transition: all 0.1s ease-in-out;

	&.active-enter-done {
		opacity: 1;
	}

	@media screen and (max-width: ${theme.point520}) {
		right: -48px;
	}
`

export const HostModalLinks = styled.div`
	border-bottom: 1px solid #cecece;
	padding-bottom: 13px;
`

export const HostModalLink = styled.div`
	padding: 12px 0;
	display: flex;
	align-items: center;
	gap: 15px;
	cursor: pointer;

	&:hover > div {
		opacity: 0.5;
	}
`

export const HostModalLinkIcon = styled.div`
	display: flex;

	> img {
		width: 21px;
		height: 21px;
	}
`

export const HostModalLinkText = styled.div`
	font-size: 15px;
	font-weight: 400;
`

export const HostModalLogoutButton = styled.div`
	width: auto;
	height: 48px;
	padding: 5px 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 12px;
	background: #724fff;
	cursor: pointer;
	box-shadow: none;
	border: none;
	outline: none;
	margin-top: 25px;

	&:hover {
		opacity: 0.5;
	}
`
