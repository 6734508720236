import styled from 'styled-components'
import { theme } from '../../style/theme'

export const HeaderWrapper = styled.header`
	width: 100%;
	height: 98px;
	background-color: #ffffff;

	@media screen and (max-width: ${theme.point520}) {
		height: 78px;
		position: static;
		z-index: 4;
		top: 0;
		left: 0;
		margin-top: 0;
		width: 100%;
		border-radius: 0 0 12px 12px;

		&.mobile-header-active {
			position: fixed;
		}
	}
`

export const HeaderInnerWrapper = styled.div`
	height: 100%;
	padding: 5px 50px;
	display: flex;
	align-items: center;
	max-width: 1380px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (max-width: ${theme.point1280}) {
		max-width: 1044px;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding-left: 16px;
		padding-right: 16px;
	}
`

export const HeaderLogo = styled.div`
	margin-right: auto;
	width: 79px;
	height: 35px;
	display: flex;
	cursor: pointer;

	> svg {
		width: 100%;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 54px;
	}
`

export const HeaderButtons = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 15px;
`

export const ServiceLink = styled.a`
	color: #282828;
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	font-style: normal;
	cursor: pointer;
	text-decoration: none;
	padding: 25px;

	&:hover {
		opacity: 0.5;
	}

	@media screen and (max-width: ${theme.point520}) {
		display: none;
	}
`

export const WriteUsButton = styled.button`
	height: 48px;
	padding: 5px 25px;
	background-color: #f5f5f5;
	border-radius: 12px;
	color: #282828;
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}

	@media screen and (max-width: ${theme.point520}) {
		padding-left: 15px;
		padding-right: 15px;
	}
`
