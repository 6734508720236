import React from 'react'
import { Autocomplete, Checkbox } from '@mui/material'
import styled from 'styled-components'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { TextFieldStyled } from '../CustomTextField'
import { useController } from 'react-hook-form'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutocompleteWrapper = styled.div`
	.MuiPaper-root.MuiPaper-elevation {
		border-radius: 12px;
	}

	.MuiAutocomplete-option {
		font-size: 13px;
	}
`
// todo: remove from global styled

const AutocompleteStyled = styled(Autocomplete)(() => ({
	width: '100%',
	'&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
		right: '19px',
		height: '12px',
		display: 'flex',
		marginTop: '1px'
	},
	'& .MuiAutocomplete-clearIndicator': {
		display: 'none'
	},
	'& .MuiAutocomplete-popupIndicator': {
		width: '12px',
		height: '12px',
		padding: '0',
		backgroundColor: 'transparent'
	},
	'& .MuiButtonBase-root': {
		margin: '0'
	},
	'& .MuiIconButton-root': {
		width: '12px',
		height: '12px',
		padding: '0',
		backgroundColor: 'transparent'
	},
	'& .MuiChip-root': {
		height: '25px',
		// borderRadius: '8px'
		'& .MuiChip-deleteIcon': {
			marginRight: '2px'
		}
	}
}))

const CheckboxStyled = styled(Checkbox)(() => ({
	'.MuiAutocomplete-option': {
		fontSize: '13px'
	}
}))

const CustomMultiAutocomplete = ({ id, name, options, control, onValue, onChange, label, placeholder, renderOption, required, defaultValue }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required }, defaultValue })

	const handleChange = (e, value) => {
		field.onChange(onChange(e, value))
	}

	return (
		<AutocompleteWrapper>
			<AutocompleteStyled
				{...{ id, multiple: true, disableCloseOnSelect: true, options, getOptionLabel: option => option.label, limitTags: 1, renderOption, name: field.name, value: onValue(field.value), onChange: handleChange, error: error, isOptionEqualToValue: (o, v) => o.value === v.value }}
				renderInput={params => <TextFieldStyled {...params} label={label} placeholder={placeholder} error={!!error} inputRef={field.ref} />}
				renderOption={(props, option, { selected }) => {
					const { key, ...optionProps } = props
					return (
						<li key={key} {...optionProps}>
							<CheckboxStyled icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
							{option.label}
						</li>
					)
				}}
				popupIcon={
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 0H12V12H0V0Z" fill="white" />
						<path
							d="M10.3052 3.01844L6.03306 7.31011L1.75974 3.01728C1.45355 2.70968 0.958825 2.69961 0.640362 2.99448C0.303366 3.30651 0.294641 3.83658 0.621184 4.15954L5.48815 8.97302C5.78958 9.27114 6.27521 9.2698 6.57499 8.97002L11.4172 4.12785C11.726 3.819 11.7235 3.31749 11.4116 3.01171C11.1036 2.70969 10.6096 2.7127 10.3052 3.01844Z"
							fill="#282828"
						/>
					</svg>
				}
			/>
		</AutocompleteWrapper>
	)
}

export default CustomMultiAutocomplete
