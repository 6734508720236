import styled from 'styled-components'
import { theme } from '../theme'

export const PageWrapper = styled.div`
	position: relative;
	padding-top: 0.01%;
`

export const PageContainer = styled.div`
	padding-right: 50px;
	padding-left: 50px;
	padding-top: 0.01%;
	max-width: 1380px;
	margin-left: auto;
	margin-right: auto;

	&.disabled {
		padding: 0;
	}

	@media screen and (max-width: ${theme.point1280}) {
		max-width: 1044px;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding-left: 16px;
		padding-right: 16px;
	}
`
