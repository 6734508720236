import axios from 'axios'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest'
}

const facebookGraphInstance = axios.create({
	baseURL: 'https://graph.facebook.com',
	headers
})

export default facebookGraphInstance
