import authInstance from '../instance'

export const createHostContext = ({ phone, name, surname, password }) => {
	return authInstance.post('/hostContexts', { phone, name, surname, password })
}

export const sendHostContextSms = hostContextId => {
	return authInstance.post(`/hostContexts/${hostContextId}/send-sms`)
}

export const verifyHostContextCode = (hostContextId, code) => {
	return authInstance.post(`/hostContexts/${hostContextId}/code`, { code })
}

export const createRecoveryHostContext = ({ phone }) => {
	return authInstance.post('/hostContexts/recovery', { phone })
}
