import styled from 'styled-components'

export const CustomCheckboxWrapper = styled.label`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
`

export const CustomCheckboxInput = styled.input`
	display: none;
`

export const CustomCheckboxBox = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 5px;
	border: 1px solid #cecece;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	> svg {
		width: 12px;
		height: 12px;
	}

	&.checked {
		background: #724fff;
		border-color: #724fff;
	}
`

export const CustomCheckboxText = styled.div`
	font-size: 15px;
	font-weight: 400;
	color: #282828;
	user-select: none;

	&.error {
		color: #ff385c;
	}
`
