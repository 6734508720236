import styled from 'styled-components'
import { theme } from '../../style/theme'
import { NavLink } from 'react-router-dom'

export const LoginPageWrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 400px;
	margin-top: 15px;
	overflow: hidden;
	padding: 40px 0;

	@media screen and (max-width: ${theme.point520}) {
		border-radius: 12px;
		padding: 0;
	}
`

export const LoginImg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;

	@media screen and (max-width: ${theme.point520}) {
		position: static;
		height: 327px;
	}
`

export const LoginInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
	}
`

export const LoginTitle = styled.div`
	color: #ffffff;
	font-size: 35px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 22px;
	}

	@media screen and (max-width: ${theme.point520}) {
		display: none;
	}
`

export const LoginFormBlock = styled.form`
	width: 410px;
	background-color: #ffffff;
	border-radius: 12px;
	overflow: hidden;
	padding: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		width: calc(50% - 15px);
		min-width: 330px;
		padding: 25px 16px;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 10px;
	}

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
		margin-top: -18px;
		position: relative;
		z-index: 1;
	}
`

export const LoginFormTitle = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	width: 80%;

	@media screen and (max-width: ${theme.point520}) {
		font-size: 18px;
	}
`

export const LoginFormSubtitle = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	line-height: 25px;
	margin-top: 15px;
`

export const LoginFormInputs = styled.div`
	margin-top: 34px;
	padding-top: 0.01%;
	display: flex;
	flex-direction: column;
	gap: 43px;
`

export const LoginFormFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
`

export const LoginFormForgot = styled(NavLink)`
	font-size: 15px;
	line-height: 18px;
	color: #724fff;
	text-decoration: none;
`

export const LoginFormNoProfile = styled.div`
	margin-top: 25px;
	font-size: 15px;
	color: #b4b4b4;
	height: 25px;
	display: flex;
	align-items: center;
	gap: 5px;

	> a {
		color: #724fff;
		text-decoration: none;
	}
`

export const LoginFormButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 48px;
	border-radius: 12px;
	border: none;
	background: #724fff;
	cursor: pointer;
	color: #ffffff;
	margin-top: 45px;

	&.disabled {
		background: #cecece;
		cursor: not-allowed;
	}
`

export const LoginFormButtonText = styled.div`
	color: #ffffff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`
