import React from 'react'
import { useController } from 'react-hook-form'
import { CustomCheckboxBox, CustomCheckboxInput, CustomCheckboxText, CustomCheckboxWrapper } from './index.styled'

const CustomCheckbox = ({ control, label, name, required }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required } })

	const layout = (field.value ? 'checked' : '') + (error ? ' error' : '')

	return (
		<CustomCheckboxWrapper>
			<CustomCheckboxInput type="checkbox" {...{ name, value: !!field.value, checked: !!field.value, onChange: field.onChange, ref: field.ref }} />
			<CustomCheckboxBox className={layout}>
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.7071 2.29571C12.0976 2.68999 12.0976 3.32925 11.7071 3.72354L5.21048 10.2827C4.8192 10.6777 4.1808 10.6777 3.78951 10.2827L0.292893 6.75241C-0.0976311 6.35812 -0.0976311 5.71886 0.292893 5.32458C0.683417 4.9303 1.31658 4.9303 1.70711 5.32458L4.5 8.14435L10.2929 2.29571C10.6834 1.90143 11.3166 1.90143 11.7071 2.29571Z"
						fill="white"
					/>
				</svg>
			</CustomCheckboxBox>
			<CustomCheckboxText className={layout}>{label}</CustomCheckboxText>
		</CustomCheckboxWrapper>
	)
}

export default CustomCheckbox
