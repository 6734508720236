import styled from 'styled-components'

export const HeaderBurgerWrapper = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 12px;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3.43px;
	margin-left: 15px;
`

export const HeaderBurgerLine = styled.div`
	width: 16px;
	height: 2.5px;
	background-color: #282828;
	border-radius: 3px;
	transition: all 0.2s linear;

	&.active {
		&:nth-child(1) {
			transform: rotate(45deg) translate(2px, 2px) scale(1.2);
		}

		&:nth-child(2) {
			display: none;
		}

		&:nth-child(3) {
			transform: rotate(-45deg) translate(2px, -2px) scale(1.2);
		}
	}
`
