import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Main from './Main'
import ErrorBoundary from './helper/ErrorBoundary'
import { GlobalStyle } from './style/global.styled'
import { createTheme, ThemeProvider } from '@mui/material'
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const theme = createTheme({
	palette: {
		primary: {
			main: '#724FFF'
			// light: '#eef436',
			// dark: '#eef436',
			// contrastText: '#eef436'
		}
		// secondary: {
		// 	main: '#eef436',
		// 	light: '#eef436',
		// 	dark: '#eef436',
		// 	contrastText: '#eef436'
		// },
		// error: {
		// 	main: '#eef436',
		// 	light: '#eef436',
		// 	dark: '#eef436',
		// 	contrastText: '#eef436'
		// },
		// warning: {
		// 	main: '#eef436',
		// 	light: '#eef436',
		// 	dark: '#eef436',
		// 	contrastText: '#eef436'
		// },
		// info: {
		// 	main: '#eef436',
		// 	light: '#eef436',
		// 	dark: '#eef436',
		// 	contrastText: '#eef436'
		// },
		// success: {
		// 	main: '#eef436',
		// 	light: '#eef436',
		// 	dark: '#eef436',
		// 	contrastText: '#eef436'
		// }
	}
})

const App = () => {
	return (
		<>
			<GlobalStyle />
			<ErrorBoundary>
				<Suspense fallback={<div>Loading..</div>}>
					<Router>
						<ThemeProvider theme={theme}>
							<Main />
							<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" transition={Bounce} />
						</ThemeProvider>
					</Router>
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

export default App
