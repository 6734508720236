import facebookGraphInstance from '../instance'
import { v4 as uuidv4 } from 'uuid'

const apiVersion = 'v20.0'
const datasetId = '1007579111119301'

export const sendFacebookGraphEvent = (eventName, hashedPhone, hashedCountry, customData = {}) => {
	return facebookGraphInstance.post(`/${apiVersion}/${datasetId}/events?access_token=${window.FB_API_CONVERSIONS_ACCESS_TOKEN}`, {
		data: [
			{
				event_name: eventName,
				event_time: Math.floor(Date.now() / 1000),
				action_source: 'website',
				event_id: uuidv4(),
				opt_out: true,
				event_source_url: 'https://host.josti.com.ua',
				user_data: {
					em: [null],
					ph: [hashedPhone],
					ge: [null],
					db: [null],
					ct: [null],
					country: [hashedCountry],
					external_id: [null],
					client_ip_address: null,
					client_user_agent: navigator.userAgent,
					fbc: null,
					fbp: null,
					subscription_id: null
				},
				custom_data: customData
			}
		]
	})
}
