import React, { useState } from 'react'
import cooperation from '../../../../img/realties/cooperation.png'
import { CooperationCross, CooperationImage, CooperationInfo, CooperationLink, CooperationText, CooperationTitle, CooperationWrapper } from './index.styled'
import CooperationModal from './component/CooperationModal'
import CooperationRequestedModal from './component/CooperationRequestedModal'

const CooperationBlock = () => {
	const [showBlock, setShowBlock] = React.useState(true)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [requestedModalIsOpen, setRequestedModalIsOpen] = useState(false)

	return (
		<>
			{showBlock && (
				<CooperationWrapper>
					<CooperationImage>
						<img src={cooperation} alt="Cooperation" />
					</CooperationImage>
					<CooperationInfo>
						<CooperationTitle>Довіртесь профі!</CooperationTitle>
						<CooperationText>
							Немає часу займатись здачею квартири? <br />
							Відправляйте заявку та ми вирішимо ваше питання.
						</CooperationText>
						<CooperationLink onClick={() => setModalIsOpen(true)}>
							Відправити заявку
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M3.01844 1.69478L7.31011 5.96694L3.01728 10.2403C2.70968 10.5465 2.69961 11.0412 2.99448 11.3596C3.30651 11.6966 3.83658 11.7054 4.15954 11.3788L8.97302 6.51185C9.27114 6.21042 9.2698 5.72479 8.97002 5.42501L4.12785 0.582845C3.819 0.273992 3.31749 0.276475 3.01171 0.588371C2.70969 0.896427 2.7127 1.39042 3.01844 1.69478Z"
									fill="white"
								/>
							</svg>
						</CooperationLink>
					</CooperationInfo>
					<CooperationCross onClick={() => setShowBlock(false)}>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M0.54956 0.0274515C0.39956 0.0766706 0.322216 0.125889 0.216747 0.233702C0.0503411 0.400108 0.0104974 0.50792 0.0104974 0.782139C0.0104974 0.981358 0.0198724 1.03526 0.0667474 1.1337C0.111279 1.22745 0.589404 1.71964 2.49722 3.6298L4.87378 6.0087L2.50659 8.37589C-0.14419 11.0267 0.0175286 10.8439 0.00346612 11.2048C-0.00590889 11.3853 0.00112237 11.4228 0.0573724 11.5493C0.130029 11.711 0.296435 11.8821 0.451122 11.9501C0.526122 11.9853 0.603466 11.997 0.772216 11.997C1.16128 11.997 0.940966 12.1892 3.6269 9.50558L5.99878 7.1337L8.373 9.50558C11.0566 12.1892 10.8363 11.997 11.2253 11.997C11.3941 11.997 11.4714 11.9853 11.5464 11.9501C11.7011 11.8821 11.8675 11.711 11.9402 11.5493C11.9964 11.4228 12.0035 11.3853 11.9941 11.2048C11.98 10.8439 12.1417 11.0267 9.49097 8.37589L7.12378 6.0087L9.50034 3.6298C11.4082 1.71964 11.8863 1.22745 11.9308 1.1337C11.9777 1.03526 11.9871 0.981358 11.9871 0.782139C11.9871 0.587608 11.9777 0.529015 11.9355 0.442295C11.8628 0.29464 11.7058 0.142296 11.5535 0.0696392C11.4433 0.0180769 11.3941 0.00870132 11.2136 0.00870132C11.0402 0.00870132 10.9816 0.0204201 10.8855 0.0649519C10.7917 0.109483 10.3019 0.585264 8.38472 2.50245L5.99878 4.8837L3.61519 2.50245C1.65815 0.550108 1.20815 0.111827 1.11206 0.0672951C0.969091 0.00167084 0.690185 -0.0170794 0.54956 0.0274515Z"
								fill="white"
							/>
						</svg>
					</CooperationCross>
				</CooperationWrapper>
			)}
			<CooperationModal {...{ modalIsOpen, setModalIsOpen, setRequestedModalIsOpen }} />
			<CooperationRequestedModal {...{ requestedModalIsOpen, setRequestedModalIsOpen }} />
		</>
	)
}

export default CooperationBlock
