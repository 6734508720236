export const formatPublicId = publicId => {
	const publicIdText = String(publicId)
	if (publicIdText.length > 3) {
		return `${publicIdText.slice(0, publicIdText.length - 3)}-${publicIdText.slice(publicIdText.length - 3, publicIdText.length)}`
	} else {
		return publicIdText
	}
}

const getCurrencyText = currency => {
	switch (currency) {
		case 'UAH':
			return '₴'
		case 'USD':
			return '$'
		default:
			return '₴'
	}
}

export const formatBigPricePerMonth = (price, currency = 'UAH') => {
	const priceText = String(price)
	if (priceText.length > 3) {
		return `${priceText.slice(0, priceText.length - 3)} ${priceText.slice(priceText.length - 3, priceText.length)} ${getCurrencyText(currency)}/міс`
	} else {
		return `${priceText} ${getCurrencyText(currency)}/міс`
	}
}

export const formatBigPriceWithCents = (price, currency = 'UAH') => {
	const priceText = String(price)
	const integerPart = priceText.indexOf('.') !== -1 ? priceText.substring(0, priceText.indexOf('.')) : priceText
	const decimalPart = priceText.indexOf('.') !== -1 ? priceText.substring(priceText.indexOf('.') + 1) : '00'
	const resultDecimalPart = decimalPart.length === 1 ? decimalPart + '0' : decimalPart.length === 0 ? '00' : decimalPart
	if (integerPart.length > 3) {
		return `${integerPart.slice(0, integerPart.length - 3)} ${integerPart.slice(integerPart.length - 3, integerPart.length)}.${resultDecimalPart} ${getCurrencyText(currency)}`
	} else {
		return `${integerPart}.${resultDecimalPart} ${getCurrencyText(currency)}`
	}
}

export const City = {
	kiev: { id: 'kiev', name: 'Київ' },
	lviv: { id: 'lviv', name: 'Львів' },
	kharkiv: { id: 'kharkiv', name: 'Харків' },
	odesa: { id: 'odesa', name: 'Одеса' },
	dnipro: { id: 'dnipro', name: 'Дніпро' },
	ivano_frankivsk: { id: 'ivano_frankivsk', name: 'Івано-Франківськ' }
}

export const getCityText = city => {
	return Object.values(City).find(c => c.id === city)?.name
}

export const districts = [
	// Kiev
	{ city: City.kiev.id, label: 'Шевченківський', ruLabel: 'Шевченковский', value: 'SHEVCHENKIVSKYI' },
	{ city: City.kiev.id, label: 'Подільський', ruLabel: 'Подольский', value: 'PODILSKY' },
	{ city: City.kiev.id, label: 'Голосіївський', ruLabel: 'Голосеевский', value: 'HOLOSIIVSKYI' },
	{ city: City.kiev.id, label: 'Дніпровський', ruLabel: 'Днепровский', value: 'DNIPROVSKY' },
	{ city: City.kiev.id, label: 'Дарницький', ruLabel: 'Дарницкий', value: 'DARNYTSKYI' },
	{ city: City.kiev.id, label: 'Оболонський', ruLabel: 'Оболонский', value: 'OBOLONSKY' },
	{ city: City.kiev.id, label: 'Святошинський', ruLabel: 'Святошинский', value: 'SVYATOSHYN' },
	// eslint-disable-next-line
	{ city: City.kiev.id, label: "Солом'янський", ruLabel: 'Соломенский', value: 'SOLOMYANSKY' },
	{ city: City.kiev.id, label: 'Печерський', ruLabel: 'Печерский', value: 'PECHERSK' },
	{ city: City.kiev.id, label: 'Деснянський', ruLabel: 'Деснянский', value: 'DESNYAN' },
	// Lviv
	{ city: City.lviv.id, label: 'Галицький', value: 'HALYTSKYI' },
	{ city: City.lviv.id, label: 'Залізничний', value: 'ZALIZNYCHNY' },
	{ city: City.lviv.id, label: 'Личаківський', value: 'LYCHAKIVSKYI' },
	{ city: City.lviv.id, label: 'Франківський', value: 'FRANKIVSKYI' },
	{ city: City.lviv.id, label: 'Шевченківський', value: 'SHEVCHENKIVSKYI' },
	{ city: City.lviv.id, label: 'Сихівський', value: 'SIKHIVSKYI' },
	// Kharkiv
	// eslint-disable-next-line
	{ city: City.kharkiv.id, label: "Основ'янський", value: 'OSNOVYANSKYI' },
	{ city: City.kharkiv.id, label: 'Індустріальний', value: 'INDUSTRIALNYI' },
	{ city: City.kharkiv.id, label: 'Слобідський', value: 'SLOBIDSKYI' },
	{ city: City.kharkiv.id, label: 'Холодногірський', value: 'KHOLODNOHIRSKYI' },
	{ city: City.kharkiv.id, label: 'Новобоварський', value: 'NOVOBOVARSKYI' },
	{ city: City.kharkiv.id, label: 'Немишлянський', value: 'NEMYSHLIANSKYI' },
	{ city: City.kharkiv.id, label: 'Шевченківський', value: 'SHEVCHENKIVSKYI' },
	{ city: City.kharkiv.id, label: 'Київський', value: 'KYIVSKYI' },
	{ city: City.kharkiv.id, label: 'Салтівський', value: 'SALTIVSKYI' },
	// Odessa
	{ city: City.odesa.id, label: 'Пересипський', value: 'PERESYPSKYI' },
	{ city: City.odesa.id, label: 'Київський', value: 'KYIVSKYI' },
	{ city: City.odesa.id, label: 'Приморський', value: 'PRIMORSKYI' },
	{ city: City.odesa.id, label: 'Хаджибейський', value: 'KHADZHYBEYSKYI' },
	// Dnipro
	{ city: City.dnipro.id, label: 'Амур-Нижньодніпровський', value: 'AMUR-NIZHNODNIPROVSKYI' },
	{ city: City.dnipro.id, label: 'Індустріальний', value: 'INDUSTRIALNYI' },
	{ city: City.dnipro.id, label: 'Новокодацький', value: 'NOVOKODATSKYI' },
	{ city: City.dnipro.id, label: 'Соборний', value: 'SOBORNYI' },
	{ city: City.dnipro.id, label: 'Самарський', value: 'SAMARSKYI' },
	{ city: City.dnipro.id, label: 'Центральний', value: 'TSENTRALNYI' },
	{ city: City.dnipro.id, label: 'Чечеловский', value: 'CHECHELOVSKYI' },
	{ city: City.dnipro.id, label: 'Шевченківський', value: 'SHEVCHENKIVSKYI' },
	// IV
	{ city: City.ivano_frankivsk.id, label: 'Центр', value: 'TSENTR' },
	{ city: City.ivano_frankivsk.id, label: 'Пасічна', value: 'PASICHNA' },
	{ city: City.ivano_frankivsk.id, label: 'Каскад', value: 'KASKAD' },
	{ city: City.ivano_frankivsk.id, label: 'Бам', value: 'BAM' },
	{ city: City.ivano_frankivsk.id, label: 'Коновальця', value: 'KONOVALTSYA' },
	{ city: City.ivano_frankivsk.id, label: 'Позитрон-Набережна', value: 'POZYTRON-NABEREZHNA' },
	{ city: City.ivano_frankivsk.id, label: 'Арсенальна', value: 'ARSENALNA' }
]

export const getDistrictText = district => {
	const label = districts.find(d => d.value === district)?.label
	return label || ''
}

export const getFormattedDateDay = date => {
	const inputDate = new Date(date)
	const months = ['січ.', 'лют.', 'бер.', 'квіт.', 'трав.', 'черв.', 'лип.', 'серп.', 'вер.', 'жовт.', 'лист.', 'груд.']
	const month = months[inputDate.getMonth()]
	const day = String(inputDate.getDate()).padStart(2, '0')
	const hours = String(inputDate.getHours()).padStart(2, '0')
	const minutes = String(inputDate.getMinutes()).padStart(2, '0')
	return `${day} ${month} ${hours}:${minutes}`
}

export const getFormattedPhone = phone => {
	if (!phone) {
		return
	}
	let cleaned = ('' + phone).replace(/\D/g, '')
	if (cleaned.length !== 12) {
		return phone
	}
	return '+380 (' + cleaned.slice(3, 5) + ') ' + cleaned.slice(5, 8) + '-' + cleaned.slice(8, 10) + '-' + cleaned.slice(10, 12)
}
