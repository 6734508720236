import React from 'react'
import { Autocomplete } from '@mui/material'
import styled from 'styled-components'
import { TextFieldStyled } from '../CustomTextField'
import { useController } from 'react-hook-form'

const AutocompleteWrapper = styled.div`
	.MuiPaper-root {
		border-radius: 12px;
		display: block;
	}

	.MuiAutocomplete-option {
		font-size: 13px;
	}
`

const AutocompleteStyled = styled(Autocomplete)(() => ({
	width: '100%',
	'&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
		right: '19px',
		height: '12px',
		display: 'flex',
		marginTop: '1px'
	},
	'& .MuiAutocomplete-clearIndicator': {
		display: 'none'
	},
	'& .MuiAutocomplete-popupIndicator': {
		width: '12px',
		height: '12px',
		padding: '0',
		backgroundColor: 'transparent'
	},
	'& .MuiButtonBase-root': {
		margin: '0'
	}
}))

const CustomAutocomplete = ({ name, label, options, control, onValue, onChange, placeholder, required }) => {
	const {
		field,
		fieldState: { error }
	} = useController({ name, control, rules: { required } })

	const handleChange = (e, value) => {
		field.onChange(onChange(e, value))
	}

	return (
		<AutocompleteWrapper>
			<AutocompleteStyled
				{...{ disablePortal: true, options, name: field.name, value: onValue(field.value), onChange: handleChange, error: error, isOptionEqualToValue: (o, v) => o.label === v }}
				renderInput={params => <TextFieldStyled {...params} label={label} placeholder={placeholder} error={!!error} inputRef={field.ref} />}
				popupIcon={
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 0H12V12H0V0Z" fill="white" />
						<path
							d="M10.3052 3.01844L6.03306 7.31011L1.75974 3.01728C1.45355 2.70968 0.958825 2.69961 0.640362 2.99448C0.303366 3.30651 0.294641 3.83658 0.621184 4.15954L5.48815 8.97302C5.78958 9.27114 6.27521 9.2698 6.57499 8.97002L11.4172 4.12785C11.726 3.819 11.7235 3.31749 11.4116 3.01171C11.1036 2.70969 10.6096 2.7127 10.3052 3.01844Z"
							fill="#282828"
						/>
					</svg>
				}
			/>
		</AutocompleteWrapper>
	)
}

export default CustomAutocomplete
