import React, { useEffect, useState } from 'react'
import { RecoveryButtonText, RecoveryForm, RecoveryFormBack, RecoveryFormButton, RecoveryFormInputs, RecoveryFormSubtitle, RecoveryFormTitle } from '../../index.styled'
import { createRecoveryHostContext } from '../../../../api/auth/hostContext'
import { CircularProgress } from '@mui/material'
import CustomTextField from '../../../../component/Form/CustomTextField'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const EnterPhone = ({ hostData, setHostData, setHostContext }) => {
	const { handleSubmit, control, reset, setError } = useForm()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (hostData) {
			reset({ ...hostData })
		}
	}, [hostData])

	const handleBack = () => {
		navigate('/')
	}

	const onSubmit = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		const resultPhone = '380' + data.phone
		try {
			const res = await createRecoveryHostContext({ phone: resultPhone })
			setHostContext({ ...res.data })
			setHostData({ ...data, resultPhone })
		} catch (error) {
			if (error.response?.data?.code === 'host_not_found') {
				setError('phone', { type: 'custom', message: 'Номер телефону не існує' })
			} else {
				toast.error(error.message)
			}
		}
		setLoading(false)
	}

	const handlePhoneFilter = value => {
		if (!value) {
			return value
		}
		let filteredValue = value.replaceAll(/[^0-9]/g, '')
		if (filteredValue.length > 9) {
			filteredValue = filteredValue.slice(0, 9)
		}
		return filteredValue
	}

	const handlePhoneFormat = value => {
		let formattedValue = ''
		if (value.length > 0) {
			formattedValue += '(' + value.slice(0, 2)
		}
		if (value.length >= 3) {
			formattedValue += ') ' + value.slice(2, 5)
		}
		if (value.length >= 6) {
			formattedValue += '-' + value.slice(5, 7)
		}
		if (value.length >= 8) {
			formattedValue += '-' + value.slice(7, 9)
		}
		return formattedValue
	}

	return (
		<RecoveryForm onSubmit={handleSubmit(onSubmit)}>
			<RecoveryFormBack onClick={handleBack}>
				<svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
					<path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7L16 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM16 7L1 7L1 9L16 9L16 7Z" />
				</svg>
				Повернутися назад
			</RecoveryFormBack>
			<RecoveryFormTitle>Забули пароль?</RecoveryFormTitle>
			<RecoveryFormSubtitle>Який номер телефону зв’язаний з вашим профілем josti?</RecoveryFormSubtitle>
			<RecoveryFormInputs>
				<CustomTextField control={control} label="Номер телефону" name="phone" prefix="+380" filter={handlePhoneFilter} format={handlePhoneFormat} required="Номер телефону обовʼязковий" minLength={{ value: 9, message: 'Номер телефону введений не повністю' }} />
			</RecoveryFormInputs>
			<RecoveryFormButton style={{ marginTop: '44px' }}>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <RecoveryButtonText>Змінити</RecoveryButtonText>}</RecoveryFormButton>
		</RecoveryForm>
	)
}

export default EnterPhone
