import React from 'react'
import Header from '../../../component/Header'
import Footer from '../../../component/Footer'
import { PageContainer, PageWrapper } from '../../../style/page/index.styled'
import { useNavigate } from 'react-router-dom'
import { createRealty } from '../../../api/realty/realty'
import RealtyForm from '../components/RealtyForm'

const NewRealtyPage = () => {
	const navigate = useNavigate()

	const handleSave = async data => {
		await createRealty(data)
		navigate('/realties')
	}

	return (
		<PageWrapper>
			<Header />
			<PageContainer>
				<RealtyForm onSave={handleSave} />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default NewRealtyPage
