import React, { useEffect, useState } from 'react'
import { RecoveryButtonText, RecoveryForm, RecoveryFormBack, RecoveryFormButton, RecoveryFormInputs, RecoveryFormSubtitle, RecoveryFormTitle } from '../../index.styled'
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CustomTextField from '../../../../component/Form/CustomTextField'
import { updateHostPassword } from '../../../../api/auth/host'

const SetNewPassword = ({ hostData, setHostData, hostContext, setHostContext }) => {
	const { handleSubmit, control, reset } = useForm()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (hostData) {
			reset({ ...hostData })
		}
	}, [hostData])

	const handleBack = () => {
		setHostData({ ...hostData, code: null })
	}

	const onSubmit = async data => {
		if (loading) {
			return
		}
		setLoading(true)
		try {
			await updateHostPassword({ hostContextId: hostContext.id, code: hostData.code, password: data.password })
			setHostContext(null)
			setHostData({})
			navigate('/')
		} catch (error) {
			toast.error(error.message)
		}
		setLoading(false)
	}

	return (
		<RecoveryForm onSubmit={handleSubmit(onSubmit)}>
			<RecoveryFormBack onClick={handleBack}>
				<svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
					<path d="M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7L16 9ZM0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM16 7L1 7L1 9L16 9L16 7Z" />
				</svg>
				Повернутися назад
			</RecoveryFormBack>
			<RecoveryFormTitle>Встановити новий пароль</RecoveryFormTitle>
			<RecoveryFormSubtitle>Введіть новий пароль, який ви будете використовувати при вході в профіль</RecoveryFormSubtitle>
			<RecoveryFormInputs>
				<CustomTextField control={control} label="Пароль" name="password" type="password" required="Пароль обовʼязковий" minLength={{ value: 8, message: 'Пароль повинен мати мінімум 8 символів' }} withPasswordLevel={true} />
			</RecoveryFormInputs>
			<RecoveryFormButton style={{ marginTop: '62px' }}>{loading ? <CircularProgress color="inherit" sx={{ padding: '10px' }} /> : <RecoveryButtonText>Змінити</RecoveryButtonText>}</RecoveryFormButton>
		</RecoveryForm>
	)
}

export default SetNewPassword
