import { sendFacebookGraphEvent } from '../../../api/facebook/graph'

const getSHA256Hash = async input => {
	const textAsBuffer = new TextEncoder().encode(input)
	const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
	const hashArray = Array.from(new Uint8Array(hashBuffer))
	const hash = hashArray.map(item => item.toString(16).padStart(2, '0')).join('')
	return hash
}

export const sendFacebookEventThunk = (eventName, phone) => async () => {
	const hashedPhone = await getSHA256Hash(phone)
	const hashedCountry = await getSHA256Hash('ua')
	try {
		await sendFacebookGraphEvent(eventName, hashedPhone, hashedCountry, {})
	} catch (fbError) {
		// eslint-disable-next-line
		console.error(fbError)
	}
}
