import { jwtDecode } from 'jwt-decode'

export const selectUserData = store => {
	const accessToken = store.auth.accessToken
	try {
		const userData = jwtDecode(accessToken)
		return {
			userId: userData.id,
			phone: userData.phone,
			status: userData.status,
			name: userData.name,
			surname: userData.surname,
			createdAt: userData.createdAt
		}
	} catch (err) {
		return {}
	}
}
