const requestInterceptor = config => {
	const accessToken = localStorage.accessToken
	if (accessToken) {
		config.headers['Authorization'] = `Bearer ${accessToken}`
		config.headers['X-Authorization'] = `Bearer ${accessToken}`
	}
	return config
}

export default requestInterceptor
