import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const RealtyFormWrapper = styled.form`
	display: grid;
	gap: 2px;
	margin-top: 15px;
`

export const FormBlock = styled.div`
	background: #ffffff;
	padding: 25px;
	border-radius: 12px;

	&.bottom {
		margin-top: 13px;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 20px 16px;
	}
`

export const FormTitle = styled.div`
	color: #282828;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	align-items: center;
	gap: 10px;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 17px;
	}
`

export const FormNumber = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #724fff;
	border-radius: 12px;
	font-size: 17px;
	color: #ffffff;
	font-weight: 500;
`

export const FormSubtitle = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 25px;
`

export const FormInputs = styled.div`
	margin-top: 25px;
	display: grid;
	gap: 32px 25px;
	grid-template-columns: repeat(3, 1fr);

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		gap: 22px;
	}
`

export const FormConfirm = styled.div`
	display: block;
	margin-bottom: 40px;
`

export const FormSwitchInputs = styled.div`
	width: 65%;
	margin-top: 15px;
	display: grid;
	gap: 25px;
	grid-template-columns: repeat(2, 1fr);
`

export const FormAdditions = styled.div`
	margin-top: 15px;
	width: 65%;

	@media screen and (max-width: ${theme.point720}) {
		width: 100%;
	}
`

export const FormButtons = styled.div`
	display: flex;
	gap: 10px;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column-reverse;
	}
`

export const FormButton = styled.button`
	min-width: 200px;
	height: 48px;
	padding: 5px 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 12px;
	background: #724fff;
	cursor: pointer;
	box-shadow: none;
	border: none;
	outline: none;

	&.transparent {
		border: 2px solid #724fff;
		background: transparent;
		color: #724fff;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-width: auto;
	}
`
