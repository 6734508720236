import realtyInstance from '../instance'

export const getAllRealties = (hasBookings, isArchived, page = 0, size = 20) => {
	return realtyInstance.get(`/host/realties?page=${page}` + `&size=${size}` + (hasBookings != null ? `&hasBookings=${hasBookings}` : '') + (isArchived != null ? `&isArchived=${isArchived}` : ''))
}

export const createRealty = realty => {
	return realtyInstance.post('/host/realties', { realty })
}

export const getRealty = realtyId => {
	return realtyInstance.get(`/host/realties/${realtyId}`)
}

export const updateRealty = (realtyId, realty) => {
	return realtyInstance.put(`/host/realties/${realtyId}`, { realty })
}

export const deleteRealty = realtyId => {
	return realtyInstance.delete(`/host/realties/${realtyId}`)
}
