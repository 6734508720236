import styled from 'styled-components'

export const ModalWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(40, 40, 40, 0.5);
	transition: all 0.1s ease-in-out;
	z-index: 1;
	opacity: 0;

	&.active-enter-done {
		opacity: 1;
	}
`

export const ModalBody = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	background: #ffffff;
	padding: 25px;
	width: 365px;
	border-radius: 12px;
	transition: all 0.1s ease-in-out;
`

export const ModalCross = styled.div`
	display: flex;
	width: 12px;
	height: 12px;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
`

export const ModalTitle = styled.div`
	font-size: 18px;
	font-weight: 500;
	color: #282828;
	margin-top: 37px;
	text-align: center;
`

export const ModalText = styled.div`
	font-size: 15px;
	font-weight: 400;
	color: #b6b7bc;
	line-height: 25px;
	margin-top: 15px;
	text-align: center;
`

export const ModalFooter = styled.div`
	margin-top: 25px;
	display: flex;
	gap: 10px;
	flex-direction: column;
`

export const CancelButton = styled.div`
	width: 100%;
	padding: 5px 25px;
	background: transparent;
	height: 48px;
	border-radius: 12px;
	font-size: 15px;
	font-weight: 400;
	color: #724fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	border: 1px solid #724fff;

	&:hover {
		opacity: 0.5;
	}
`

export const ConfirmButton = styled.div`
	width: 100%;
	padding: 5px 25px;
	background: #724fff;
	height: 48px;
	border-radius: 12px;
	font-size: 15px;
	font-weight: 400;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;

	&:hover {
		opacity: 0.5;
	}
`
