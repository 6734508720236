import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	accessToken: localStorage.accessToken || null,
	refreshToken: localStorage.refreshToken || null
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAccessToken: (state, action) => {
			state.accessToken = action.payload
			if (!action.payload) {
				localStorage.removeItem('accessToken')
			} else {
				localStorage.accessToken = action.payload
			}
		},
		setRefreshToken: (state, action) => {
			state.refreshToken = action.payload
			localStorage.refreshToken = action.payload
		}
	}
})

export const { setAccessToken, setRefreshToken } = authSlice.actions
export const authReducer = authSlice.reducer
