import React, { useRef, useState } from 'react'
import { HeaderHostImage, HeaderHostWrapper, HostModal, HostModalLink, HostModalLinkIcon, HostModalLinks, HostModalLinkText, HostModalLogoutButton, HostModalShadow } from './index.styled'
import { CSSTransition } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { logoutThunk } from '../../../../store/auth/thunk'
import { useNavigate } from 'react-router-dom'
import keys from '../../../../img/header/keys.png'
import wallet from '../../../../img/header/wallet.png'
import profile from '../../../../img/header/profile.png'

const HeaderHost = ({ mobileHeaderIsOpen }) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const modalRef = useRef(null)
	const modalShadowRef = useRef(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const hasAccessToken = useSelector(state => !!state.auth.accessToken)

	const toggleOpenModal = () => {
		if (mobileHeaderIsOpen) {
			return
		}
		setModalIsOpen(!modalIsOpen)
	}

	const handleCloseModal = () => {
		setModalIsOpen(false)
	}

	const handleMyRealties = () => {
		handleCloseModal()
		navigate('/realties')
	}

	const handleMyBalance = () => {
		handleCloseModal()
		navigate('/balance')
	}

	const handleMyProfile = () => {
		handleCloseModal()
		navigate('/profile')
	}

	const handleLogout = () => {
		dispatch(logoutThunk())
	}

	return (
		hasAccessToken && (
			<>
				<HeaderHostWrapper>
					<HeaderHostImage onClick={toggleOpenModal}>
						<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="24" cy="24" r="24" fill="#724FFF" />
							<path d="M29.0912 28.7624C25.273 37.9217 19.5458 33.0226 18.6973 29.4015L20.1821 28.7624C22.5579 35.4082 26.5458 31.9575 27.8185 28.3364L29.0912 28.7624Z" fill="#282828" />
							<path d="M23.1516 20.2425C23.1516 24.2896 20.2075 27.0587 16.5758 27.0587C12.5455 27.0587 10 23.6506 10 20.2425C10 16.8344 11.1667 13.4264 16.5758 13.4263C19.3334 13.4263 23.1516 16.478 23.1516 20.2425Z" fill="white" />
							<path d="M19.3335 20.2417C19.3335 21.6534 18.485 23.0108 16.3638 23.0108C14.6668 23.0108 13.8184 21.7328 13.8184 19.8157C13.8184 18.404 14.6668 17.4727 16.3638 17.4727C18.0608 17.4727 19.3335 18.8301 19.3335 20.2417Z" fill="#282828" />
							<path d="M38.0002 19.8162C38.0002 24.0763 35.2682 26.6324 31.6365 26.6324C28.2426 26.6324 24.8486 24.0763 24.8486 20.2422C24.8486 16.4081 26.5456 13 31.2123 13C34.8184 13 38.0002 16.1951 38.0002 19.8162Z" fill="white" />
							<path d="M34.1822 19.8164C34.1822 22.1595 32.6973 22.5855 31.2125 22.5855C29.8066 22.5855 28.667 21.2281 28.667 19.8164C28.667 18.4048 29.5155 17.0474 31.2125 17.0474C32.6183 17.0474 34.1822 18.1124 34.1822 19.8164Z" fill="#282828" />
						</svg>
					</HeaderHostImage>
					<CSSTransition nodeRef={modalRef} in={modalIsOpen} timeout={10} classNames="active" unmountOnExit>
						<HostModal ref={modalRef}>
							<HostModalLinks>
								<HostModalLink onClick={handleMyRealties}>
									<HostModalLinkIcon>
										<img src={keys} alt="Keys" />
									</HostModalLinkIcon>
									<HostModalLinkText>Мої оголошення</HostModalLinkText>
								</HostModalLink>
								<HostModalLink onClick={handleMyBalance}>
									<HostModalLinkIcon>
										<img src={wallet} alt="Wallet" />
									</HostModalLinkIcon>
									<HostModalLinkText>Мої рахунки</HostModalLinkText>
								</HostModalLink>
								<HostModalLink onClick={handleMyProfile}>
									<HostModalLinkIcon>
										<img src={profile} alt="Profile" />
									</HostModalLinkIcon>
									<HostModalLinkText>Налаштування профілю</HostModalLinkText>
								</HostModalLink>
							</HostModalLinks>
							<HostModalLogoutButton onClick={handleLogout}>Вийти</HostModalLogoutButton>
						</HostModal>
					</CSSTransition>
				</HeaderHostWrapper>
				<CSSTransition nodeRef={modalShadowRef} in={modalIsOpen} timeout={10} classNames="active" unmountOnExit>
					<HostModalShadow ref={modalShadowRef} onClick={handleCloseModal} />
				</CSSTransition>
			</>
		)
	)
}

export default HeaderHost
