import React, { useState } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { PageContainer, PageWrapper } from '../../style/page/index.styled'
import ConfirmPhone from './component/ConfirmPhone'
import CreateProfile from './component/CreateProfile'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'

const RegistrationPage = () => {
	const [hostContext, setHostContext] = useState(false)
	const [hostData, setHostData] = useState({})
	const isSmallTablet = useWindowBreakPoint(theme.point520)

	return (
		<PageWrapper>
			<Header />
			<PageContainer className={isSmallTablet ? 'disabled' : ''}>
				{!hostContext && <CreateProfile {...{ hostData, setHostData, setHostContext }} />}
				{hostContext && <ConfirmPhone {...{ hostData, hostContext, setHostContext }} />}
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default RegistrationPage
