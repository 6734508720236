import React, { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { CancelButton, ConfirmButton, ModalBody, ModalCross, ModalFooter, ModalText, ModalTitle, ModalWrapper } from './index.styled'
import cross from '../../img/realties/cross.png'

const AskModal = ({ title, text, isOpen, handleClose, handleConfirm }) => {
	const modalRef = useRef(null)

	const handleBodyClick = e => {
		e.stopPropagation()
	}

	return (
		<CSSTransition nodeRef={modalRef} in={isOpen} timeout={10} classNames="active" unmountOnExit>
			<ModalWrapper ref={modalRef} onClick={handleClose}>
				<ModalBody onClick={handleBodyClick}>
					<ModalCross onClick={handleClose}>
						<img src={cross} alt="Cross" />
					</ModalCross>
					<ModalTitle>{title}</ModalTitle>
					<ModalText>{text}</ModalText>
					<ModalFooter>
						<ConfirmButton onClick={handleConfirm}>Погодити</ConfirmButton>
						<CancelButton onClick={handleClose}>Скасувати</CancelButton>
					</ModalFooter>
				</ModalBody>
			</ModalWrapper>
		</CSSTransition>
	)
}

export default AskModal
