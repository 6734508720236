import styled from 'styled-components'
import { theme } from '../../style/theme'

export const RecoveryForm = styled.form`
	width: 410px;
	margin: 15px auto 0;
	background-color: #ffffff;
	border-radius: 12px;
	overflow: hidden;
	padding: 25px;

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
		padding: 25px 16px;
	}
`

export const RecoveryFormBack = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	color: rgba(136, 136, 136);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 25px;
	cursor: pointer;

	> svg {
		width: 16px;
		height: 16px;
		fill: #282828;
	}
`

export const RecoveryFormTitle = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	width: 80%;
`

export const RecoveryFormSubtitle = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	line-height: 25px;
	margin-top: 15px;
`

export const RecoveryFormInputs = styled.div`
	margin-top: 34px;
	padding-top: 0.01%;
	display: flex;
	flex-direction: column;
	gap: 43px;
`

export const RecoveryFormButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 48px;
	border-radius: 12px;
	border: none;
	background: #724fff;
	cursor: pointer;
	color: #ffffff;

	&:disabled {
		background: #cecece;
		cursor: not-allowed;
	}
`

export const RecoveryButtonText = styled.div`
	color: #ffffff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const PhoneCodeForm = styled.div`
	margin-top: 50px;

	> ${RecoveryFormButton} {
		margin-top: 25px;
	}
`

export const PhoneCodeItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	position: relative;
`

export const PhoneCodeInput = styled.input`
	caret-color: transparent;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
	background: transparent;
	border: none;
	box-shadow: none;
	color: transparent;
`

export const PhoneCodeItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 49px;
	height: 64px;
	background: #cecece;
	padding: 10px;
	border-radius: 7px;
	color: #282828;
	font-size: 25px;
	font-weight: 500;

	&.filled {
		border: 1px solid #cecece;
		background: #ffffff;
	}

	&.error {
		border: 1px solid #e43027;
	}
`

export const RepeatSmsText = styled.div`
	margin-top: 87px;
	text-align: center;
	color: #a5acb0;
	font-size: 15px;
	font-weight: 400;
`

export const RepeatSmsLink = styled.span`
	color: #724fff;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
`
