import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const ImagesWrapper = styled.div`
	display: grid;
	gap: 25px;
	margin-top: 100px;
	align-items: center;
	grid-template-columns: repeat(5, 1fr);

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 41px;
		grid-template-columns: repeat(2, 1fr);
	}
`

export const ImageItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover .cross {
		visibility: visible;
	}

	&:hover .mark-main {
		visibility: visible;
	}
`

export const ImageItemCross = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -12px;
	right: -12px;
	width: 24px;
	height: 24px;
	visibility: hidden;

	> svg {
		width: 100%;
		height: 100%;
	}
`

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 139px;
	overflow: hidden;
	border-radius: 12px;
	background: #d9d9d9;
	border: 1px solid #cecece;

	> img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
`

export const MainImageButton = styled.button`
	height: 38px;
	width: 100%;
	margin-top: 10px;
	border-radius: 10px;
	background: #724fff;
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
`

export const MarkMainImageButton = styled.button`
	height: 38px;
	width: 100%;
	margin-top: 10px;
	border-radius: 10px;
	background: transparent;
	border: 1px solid #724fff;
	color: #724fff;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	visibility: hidden;

	&:hover {
		opacity: 0.5;
	}
`

export const ImageInputButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 15px;

	> input {
		display: none;
	}
`

export const ImageInputTitle = styled.div`
	color: #b4b4b4;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	margin-top: 15px;
	max-width: 193px;

	&.error {
		color: #e43027;
	}
`

export const ImageButton = styled.button`
	width: auto;
	height: 48px;
	padding: 5px 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	border-radius: 12px;
	background: #724fff;
	cursor: pointer;
	box-shadow: none;
	border: none;
	outline: none;

	&.transparent {
		border: 2px solid #724fff;
		background: transparent;
		color: #724fff;
	}

	&.disabled {
		opacity: 0.5;
	}

	&.error {
		border: 1px solid #e43027;
	}

	&:hover {
		opacity: 0.5;
	}
`
